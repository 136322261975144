export default {
    // Top Header bar
    HEADER_services: 'Services',
    HEADER_integration_link_text: 'Integration',
    HEADER_integration_summary_text:
        'Build processes that transform and integrate data between systems.',
    HEADER_datahub_link_text: 'DataHub',
    HEADER_datahub_summary_text:
        'Synchronize, cleanse, match, and enrich trusted data across domains.',
    HEADER_apim_link_text: 'API Management',
    HEADER_apim_summary_text: 'Manage and control the usage of APIs throughout their lifecycle.',
    HEADER_auto_link_text: 'Task Automation',
    HEADER_auto_summary_text: 'Create daily tasks with simple, no-code automations.',
    HEADER_event_streams_link_text: 'Event Streams',
    HEADER_event_streams_summary_text: 'Create, manage and publish message queues & streams.',

    HEADER_resources: 'Resources',
    HEADER_docs_link_text: 'Documentation',
    HEADER_docs_summary_text: 'Read the official documentation for all current features.',
    HEADER_support_link_text: 'Support',
    HEADER_support_summary_text: 'Open a support case to resolve issues and get answers.',
    HEADER_community_link_text: 'Community',
    HEADER_community_summary_text:
        'Ask questions, share knowledge, and join groups with other users.',
    HEADER_training_link_text: 'Training',
    HEADER_training_summary_text:
        "Develop core competencies as part of Boomi's certification paths.",
    HEADER_discover_link_text: 'Discover',
    HEADER_discover_summary_text: 'Discover is your launchpad for all things Boomi.',
    HEADER_billing_link_text: 'Usage & Billing',
    HEADER_billing_summary_text: 'View platform usage and billing details.',

    HEADER_labs: 'Labs',
    HEADER_spaces_link_text: 'Spaces (Learn More)',
    HEADER_spaces_summary_text:
        'Create simplified portals where users can interact with curated integrations.',
    HEADER_bundles_link_text: 'Bundles',
    HEADER_bundles_summary_text: 'Create, manage, install and share multi-product solutions.',
    HEADER_packs_link_text: 'Integration Packs',
    HEADER_packs_summary_text: 'Create pre-defined and ready-to-install integration packages.',
    HEADER_process_lib_link_text: 'Process Library',
    HEADER_process_lib_summary_text: 'Curate a library of custom Integration process templates.',
    HEADER_connectors_link_text: 'Connectors',
    HEADER_connectors_summary_text: 'Create and manage custom connectors.',

    HEADER_settings: 'Settings',
    HEADER_org_link_text: 'Organization',
    HEADER_org_summary_text: 'Manage your Flow organization.',
    HEADER_tenant_link_text: 'Tenant',
    HEADER_tenant_summary_text: 'Edit tenant settings, security, users.',
    HEADER_environments_link_text: 'Environments',
    HEADER_environments_summary_text: 'Manage your environments.',

    HEADER_user_settings: 'User Settings',
    HEADER_change_tenant: 'Change Tenant',
    HEADER_sign_out: 'Sign Out',

    SUBFLOW_select_label: 'Select a flow to run as a subflow',
    SUBFLOW_value_select_label: 'Select a value which contains, or will contain, a Flow ID',
    SUBFLOW_select_placeholder: 'Select a subflow',
    SUBFLOW_comment_label: 'Comments about this Subflow',
    SUBFLOW_name_input_label: 'Name',
    SUBFLOW_name_input_validation_message: 'Please enter a name.',
    SUBFLOW_type_input_label: 'Subflow Type',
    SUBFLOW_type_input_placeholder: 'Select Subflow Type',
    SUBFLOW_type_input_validation_message: 'Subflow Type field is required',
    SUBFLOW_flow_select_validation_message: 'Please select a flow.',
    SUBFLOW_auto_match_arguments_button_label: 'Auto match all value arguments',

    ELEMENT_delete: 'Delete ${elementType}',
    ELEMENT_delete_confirm: 'Permanently delete the "${developerName}" ${elementType}?',
    ELEMENT_delete_dependencies: 'This will also delete the following elements:',
    ELEMENT_delete_multi: 'Delete multiple elements',
    ELEMENT_delete_multi_body: 'Permanently delete the following elements?',

    // Table headers
    COMMON_TABLE_name: 'Name',
    COMMON_TABLE_last_modified: 'Last Modified',
    COMMON_TABLE_summary: 'Summary',
    COMMON_TABLE_description: 'Description',
    COMMON_TABLE_actions: 'Actions',
    COMMON_TABLE_value: 'Value',
    COMMON_TABLE_type: 'Type',
    COMMON_TABLE_type_name: 'Type Name',
    COMMON_TABLE_kind: 'Kind',
    COMMON_TABLE_property: 'Property',
    COMMON_TABLE_attribute: 'Attribute',
    COMMON_TABLE_column: 'Column',
    COMMON_TABLE_property_name: 'Property Name',
    COMMON_TABLE_order: 'Order',
    COMMON_TABLE_criteria: 'Criteria',
    COMMON_TABLE_id: 'ID',
    COMMON_TABLE_state_id: 'State ID',
    COMMON_TABLE_version_id: 'Version ID',
    COMMON_TABLE_version: 'Version',
    COMMON_TABLE_connector: 'Connector',
    COMMON_TABLE_key: 'Key',
    COMMON_TABLE_key_name: 'Key Name',
    COMMON_TABLE_label: 'Label',
    COMMON_TABLE_labels: 'Labels',
    COMMON_TABLE_comment: 'Comment',
    COMMON_TABLE_deployment_notes: 'Deployment Notes',
    COMMON_TABLE_flow_url: 'Flow URL',
    COMMON_TABLE_event_type: 'Event Type',
    COMMON_TABLE_date_activated: 'Date Activated',
    COMMON_TABLE_date_joined: 'Date Joined',
    COMMON_TABLE_occurred_at_utc: 'Occurred At (UTC)',
    COMMON_TABLE_created_at_utc: 'Created At (UTC)',
    COMMON_TABLE_last_action_utc: 'Last Action (UTC)',
    COMMON_TABLE_actioned_by: 'Actioned By',
    COMMON_TABLE_flow: 'Flow',
    COMMON_TABLE_flow_id: 'Flow ID',
    COMMON_TABLE_flow_name: 'Flow Name',
    COMMON_TABLE_entries: 'Entries',
    COMMON_TABLE_number_of_states: 'Number of States',
    COMMON_TABLE_uri: 'URI',
    COMMON_TABLE_join_uri: 'Join URI',
    COMMON_TABLE_message: 'Message',
    COMMON_TABLE_status: 'Status',
    COMMON_TABLE_last_seen: 'Last Seen',
    COMMON_TABLE_status_code: 'Status Code',
    COMMON_TABLE_response_body: 'Response Body',
    COMMON_TABLE_response_headers: 'Response Headers',
    COMMON_TABLE_method: 'Method',
    COMMON_TABLE_map_element_name: 'Map Element Name',
    COMMON_TABLE_map_element_id: 'Map Element ID',
    COMMON_TABLE_current_step: 'Current Step',
    COMMON_TABLE_next_map_element: 'Next Map Element',
    COMMON_TABLE_tenant: 'Tenant',
    COMMON_TABLE_tenant_name: 'Tenant Name',
    COMMON_TABLE_tenant_id: 'Tenant ID',
    COMMON_TABLE_shared_tenants: 'Shared Tenants',
    COMMON_TABLE_user: 'User',
    COMMON_TABLE_users: 'Users',
    COMMON_TABLE_element_type: 'Element Type',
    COMMON_TABLE_elapsed_time: 'Elapsed Time',
    COMMON_TABLE_primitive_type: 'Primitive Type',
    COMMON_TABLE_content_format: 'Content Format',
    COMMON_TABLE_listener_type: 'Listener Type',
    COMMON_TABLE_navigation: 'Navigation',
    COMMON_TABLE_item: 'Item',
    COMMON_TABLE_location: 'Location',
    COMMON_TABLE_enabled: 'Enabled',
    COMMON_TABLE_visible: 'Visible',
    COMMON_TABLE_required: 'Required',
    COMMON_TABLE_default: 'Default',
    COMMON_TABLE_operation: 'Operation',
    COMMON_TABLE_accepted_value: 'Accepted Value',
    COMMON_TABLE_organization: 'Organization',
    COMMON_TABLE_date_sent: 'Date Sent',
    COMMON_TABLE_email: 'Email',
    COMMON_TABLE_first_name: 'First Name',
    COMMON_TABLE_last_name: 'Last Name',
    COMMON_TABLE_role: 'Role',
    COMMON_TABLE_connection_name: 'Connection Name',
    COMMON_TABLE_chart_type: 'Chart Type',
    COMMON_TABLE_content_type: 'Content Type',
    COMMON_TABLE_start_ip_address: 'Start IP Address',
    COMMON_TABLE_end_ip_address: 'End IP Address',
    COMMON_TABLE_group_name: 'Group Name',
    COMMON_TABLE_page_id: 'Page ID',
    COMMON_TABLE_page_name: 'Page Name',
    COMMON_TABLE_page_component_id: 'Page Component ID',
    COMMON_TABLE_page_component_name: 'Page Component Name',
    COMMON_TABLE_saml_metadata: 'SAML Metadata',
    COMMON_TABLE_wait: 'Wait',
    COMMON_TABLE_launches: 'Launches',
    COMMON_TABLE_launches_percentage: '% of launches',
    COMMON_TABLE_usage: 'Usage',
    COMMON_TABLE_usage_percentage: '% of usage',
    COMMON_TABLE_anomalies: 'Anomalies',
    COMMON_TABLE_errors: 'Errors',
    COMMON_TABLE_items_per_page: 'Items per page:',
    COMMON_TABLE_no_results: 'No Results',
    COMMON_TABLE_items_range: '${firstNumber} to ${secondNumber}',

    COMMON_select_flow_placeholder: 'Select a flow...',
    COMMON_back: 'Back',
    COMMON_delete: 'Delete',
    COMMON_edit: 'Edit',
    COMMON_delete_param: 'Delete ${param}',
    COMMON_cancel: 'Cancel',
    COMMON_save: 'Save',
    COMMON_open: 'Open',
    COMMON_close: 'Close',
    COMMON_view: 'View',
    COMMON_cannot_be_undone: 'This cannot be undone.',
    COMMON_required: 'Required',
    COMMON_remove: 'Remove',
    COMMON_upload_placeholder: 'Drag and drop, or browse your files',
    COMMON_confirm: 'Confirm',
    COMMON_update: 'Update',
    COMMON_add: 'Add',
    COMMON_ok: 'Okay',
    COMMON_search: 'Search',
    COMMON_comments: 'Comments',
    COMMON_name: 'Name',

    COMMON_SELECT_no_results_found: 'No results found',

    HOME_getting_started_header: 'Nothing to see here.',
    HOME_getting_started_content:
        'Once you create or install a Flow, it will show up here. To find out more about getting started with building your first Flow see',
    HOME_getting_started_content_end: 'here',
    HOME_new_flow: 'New Flow',
    HOME_flow_users_just_you: 'Just you...',
    HOME_flow_search_placeholder: 'Search',
    HOME_flow_filter_placeholder: 'Filter by label...',
    HOME_flow_filter_none: 'No labels',
    HOME_heading: 'Flows',
    HOME_welcome_back: 'Welcome back',
    HOME_label_maximum_length_error:
        'The label "${label}" exceeds the maximum 50 character length. Enter a new label that is less than 50 characters.',
    HOME_label_placeholder: 'Click to add a label',
    HOME_delete_confirm: 'Are you sure you want to delete the "${flowName}" flow?',
    HOME_loading_flows: 'Loading flows...',

    GENERIC_required_symbol: '*',

    FLOW_CREATE_NEW: 'Create New Flow',
    //Wait Element
    WAIT_ELEMENT_comments_label: 'Comments about this Wait',
    WAIT_is_headless: 'Should run headlessly',
    WAIT_default_name: 'Wait',
    WAIT_min_help: 'Minimum wait that can be configured is',
    WAIT_max_help: 'Maximum wait that can be configured is',
    WAIT_range_help: 'The wait has to be between',
    WAIT_default_timezone: 'The default timezone is UTC.',
    WAIT_your_timezone: 'Your timezone is',
    WAIT_type_required: 'Wait Type field is required',
    WAIT_type_select: 'Select Wait Type',
    WAIT_days: 'Days',
    WAIT_hours: 'Hours',
    WAIT_minutes: 'Minutes',
    WAIT_seconds: 'Seconds',
    WAIT_negative_error: 'Negative time values are not valid.',
    WAIT_timefield_error: 'All time fields are required.',
    WAIT_phrase: 'Phrase',
    WAIT_phrase_help_1: 'Examples of valid phrases are:',
    WAIT_phrase_help_2: '10 seconds from now',
    WAIT_phrase_help_3: 'End of the week',
    WAIT_phrase_help_4: 'Next Monday',
    WAIT_phrase_help_5: 'In 2 days',
    WAIT_relative_error: 'The relative wait does not match any supported phrases.',
    WAIT_point_in_time: 'Point in time',
    WAIT_enter_absolute: 'Please enter a date and time.',
    WAIT_invalid_value: 'Value must be a Date/Time, a String or a Number.',
    WAIT_outcome_error: 'You must provide an outcome to trigger',
    WAIT_default_outcome: 'Please select an Outcome',
    WAIT_outcome_help: 'On completion of the wait this outcome will be triggered.',
    VALUE: 'Value',
    AND: 'and',
    //end Wait Element
    // OpenApi Map Element
    OPENAPI_actions_label: 'OpenApi Actions',
    OPENAPI_add_actions: 'Add OpenApi Action',
    OPENAPI_ELEMENT_comments_label: 'Comments about this OpenApi operation',
    OPENAPI_no_schema:
        'No OpenApi schema information was found for this value, please choose a different value.',
    OPENAPI_invalid_parameters: 'All required parameters must be set.',
    OPENAPI_actions_for: 'OpenApi Action for: ',
    OPENAPI_select_path: 'Select a Path',
    OPENAPI_select_connector: 'Select a Connector',
    OPENAPI_parameters: 'Parameters',
    OPENAPI_schema: 'Schema',
    OPENAPI_security: 'Security',
    OPENAPI_security_oidc: 'OIDC',
    OPENAPI_security_oauth: 'OAUTH2',
    OPENAPI_security_basic: 'Basic',
    OPENAPI_security_none: 'None',
    OPENAPI_security_userinfo: 'User Information URL',
    OPENAPI_security_firstname: 'First Name',
    OPENAPI_security_lastname: 'Last Name',
    OPENAPI_security_email: 'Email',
    OPENAPI_security_username: 'Username',
    OPENAPI_security_userId: 'User ID',
    OPENAPI_security_scopes: 'Scopes',
    OPENAPI_validation_fail: 'Please correct validation errors.',
    OPENAPI_value: 'Value',
    OPENAPI_connector: 'Connector',
    OPENAPI_invalid_connector: 'Please select a connector.',
    OPENAPI_invalid_path: 'Please select a path.',
    OPENAPI_configuration_values_warning_message:
        'If the Schema is changed then you must reconfigure the OpenAPI configuration.',
    OPENAPI_delete_type: 'Delete Type',
    // end OpenApi Map Element
    VS_duplicate_value_1: 'A Value already exists with the name "${value}".',
    VS_duplicate_value_2: 'Are you sure you want to make a new Value with the same name?',
    VS_select_button_text: 'Select or create a new ${element}',
    VS_loading_text: 'Loading...',
    SF_no_matching_values_text: 'There are no values to match.',
    AV_private_access_value: 'No external access',
    AV_input_access_value: 'Value can be initialized when the flow starts',
    AV_output_access_value: 'Value is returned from the flow',
    AV_input_output_access_value: 'Value is set as both',
    AV_public_access_value: 'Value is automatically passed to and from subflows',

    SC_connectors_title: 'Connectors',
    SC_connectors_new_button: 'New Connector',
    SC_connectors_refresh_button: 'Refresh Results',
    SC_table_name: 'Name',
    SC_table_url: 'URL',
    SC_table_description: 'Description',
    SC_table_date_modified: 'Date last modified',
    SC_table_actions: 'Actions',
    SC_table_actions_delete_title: 'Delete Connector',

    SC_config_refresh_failed:
        'There was the following error contacting the connector for configuration values:',
    SC_delete_service_1: 'Are you sure you want to delete the connector "${serviceName}"?',
    SC_delete_confirmation_title: 'Delete Connector',
    SC_successful_delete: 'The connector "${serviceName}" was successfully deleted',
    SC_service_tile_url_label: 'URL:',
    SC_saml_logo_alt_text: 'SAML connector logo',
    SC_s3_logo_alt_text: 'S3 connector logo',
    SC_azure_logo_alt_text: 'Azure connector logo',
    SC_email_logo_alt_text: 'Email connector logo',
    SC_hub_logo_alt_text: 'Hub connector logo',
    SC_okta_logo_alt_text: 'Okta connector logo',
    SC_pdf_logo_alt_text: 'PDF connector logo',
    SC_sql_logo_alt_text: 'SQL connector logo',
    SC_graphql_logo_alt_text: 'GraphQL connector logo',
    SC_integration_logo_alt_text: 'Integration connector logo',
    SC_timer_logo_alt_text: 'Timer connector logo',
    SC_edit_connector_details: 'View Details',
    SC_salesforce_logo_alt_text: 'Salesforce connector logo',
    SC_custom_logo_alt_text: 'Custom connector logo',
    SC_openapi_logo_alt_text: 'OpenAPI connector logo',
    SC_authentication_text: 'Authentication',
    SC_identity_provider_none_selected_text: 'Select Identity Provider',

    SC_configuration_values_warning_message:
        'Flows using this connector may display errors if you have changed the connection URL or configuration values without refreshing the connector configuration data.',
    SC_configuration_values_not_required_message:
        'Click Install to continue. No configuration values are required for this connector.',

    SC_name_field_validation_message: 'Please enter a name.',
    SC_url_field_validation_message: 'Please enter a valid URL.',
    SC_path_field_validation_message: 'Please enter a valid path.',
    SC_atom_field_validation_message: 'Please select an Atom type.',

    SAML_warning_1: 'Warning:',
    SAML_warning_2:
        'You are currently logged in using SSO, so before making changes to your SSO configuration, please ensure you can still authenticate using your standard username/password credentials to prevent accidental lockouts.',
    SAML_enable_label: 'Enable SAML Single Sign-On',
    SAML_certificate_label: 'Certificate',
    SAML_certificate_help:
        'A PEM-encoded public X.509 certificate that will be used by Flow to validate SAML messages.',
    SAML_redirect_url_label: 'Redirect URI',
    SAML_redirect_url_help:
        'The URI to redirect users to in order for them to authenticate in the IDP.',
    SAML_login_url_label: 'Flow Login URL',
    SAML_login_url_help_1: 'The URL that SSO users will use to log into Flow.',
    SAML_login_url_help_2: 'When using this URL, users will only be able to log into this tenant.',
    SAML_logout_url_label: 'Logout Redirect URI',
    SAML_logout_url_help: 'The URI to redirect users to after logging out.',
    SAML_algorithm_label: 'Signature Algorithm',
    SAML_algorithm_help: 'The algorithm used to sign SAML messages.',
    SAML_firstname_label: 'First Name Claim Attribute Name',
    SAML_firstname_help_1:
        'If the SAML assertion generated by your IDP does not include the following claim attribute names, then you may optionally add a custom name:',
    SAML_firstname_help_2:
        '"first_name", "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname", "User.FirstName", "givenName".',
    SAML_lastname_label: 'Last Name Claim Attribute Name',
    SAML_lastname_help_1:
        'If the SAML assertion generated by your IDP does not include the following claim attribute names, then you may optionally add a custom name:',
    SAML_lastname_help_2:
        '"last_name", "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname", "User.LastName", "sn".',
    SAML_email_label: 'Email Address Claim Attribute Name',
    SAML_email_help_1:
        'If the SAML assertion generated by your IDP does not include the following claim attribute names, then you may optionally add a custom name:',
    SAML_email_help_2:
        '"User.email", "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress", "email".',
    SAML_groupname_label: 'Group Claim Attribute Name',
    SAML_groupname_help:
        'If you wish to restrict tenant access based on groups in your IDP, then add the claim attribute name that references groups inside the SAML assertion.',
    SAML_groups_label: 'Groups',
    SAML_groups_group_input_placeholder: 'Enter a group to add',
    SAML_groups_help_1:
        'If you wish to restrict tenant access based on groups in your IDP, then add each group.',
    SAML_groups_help_2:
        'A user must be a member of at least one of these groups to be authorized to access the tenant.',
    SAML_groups_remove_group_button_label: 'Remove group',
    SAML_groups_invalid_group_name_error_message: 'Please enter a valid group name',
    SAML_groups_duplicate_group_name_error_message:
        'The group named ${group} has already been added',
    SAML_cookietype_persistent: 'Persistent',
    SAML_cookietype_transient: 'Transient',
    SAML_cookietype_label: 'Cookie type',
    SAML_cookietype_help:
        "Choose whether cookies persist until their expiry or if they're cleared on browser close.",
    SAML_max_session_label: 'Maximum SSO session',
    SAML_max_session_placeholder: 'Maximum session length in minutes',
    SAML_max_session_help: 'The maximum SSO session in minutes allowed regardless of activity.',
    SAML_max_session_show_uri: 'Show login link',
    SAML_max_session_show_uri_help:
        'Show login link when session is near expiry, so users can refresh their SSO session before reaching the maximum.',
    SAML_idle_timeout: 'Maximum inactive SSO session',
    SAML_idle_timeout_placeholder: 'Maximum inactive session length in minutes',
    SAML_idle_timeout_help: 'The maximum SSO session in minutes allowed for inactive users.',

    FORG_leave_confirmation_title: 'Leave Organization',
    FORG_leave_confirmation: 'Are you sure you want to leave this organization?',
    FORG_left_confirmation: '${user} successfully left the organization',
    FORG_invite_tenant_title: 'Invite a tenant',
    FORG_invite_user_title: 'Invite a user to become an administrator of this organization',
    FORG_provision_title: 'Create Tenant',
    FORG_provision_description:
        "Create a new Tenant within this organization. A user needs to be added to the Tenant. If the user doesn't have an account this will start the process of provisioning a new account for that email address.",
    FORG_provision_add_user_text: 'Add',
    FORG_provision_invalid_email_text: 'is not a valid email address',
    FORG_provision_no_results_text: 'No results found',
    FORG_tenant_user_provisioned: 'Your new tenant has been provisioned.',
    FORG_remove_tenant_title: 'Remove Tenant',
    FORG_remove_tenant_message: 'Are you sure you want to remove ${name} from this organization?',
    FORG_remove_user_title: 'Remove User',
    FORG_remove_user_message: 'Are you sure you want to remove ${name} from this organization?',
    FORG_tenant_invitations_description:
        'Invitations sent to your tenant. Accept an invitation to add your tenant into the requesting Organization.',
    FORG_user_invitations_description:
        'Invitations sent to your user account. Accept an invitation to add your user account into the requesting Organization.',
    FORG_received_user_invites_table_caption: 'Invitations for user: ${userEmail}',
    FORG_received_tenant_invites_table_caption: 'Invitations for tenant: ${tenantName}',

    FORG_tenant_invite_form_tenant_name_input_label: 'Name',
    FORG_tenant_invite_form_tenant_id_input_label: 'ID',
    FORG_tenant_invite_form_name_help_text:
        'An alphanumeric (a-z, A-Z, 0-9) string optionally containing hyphens (-) and periods (.)',
    FORG_invite_form_id_help_text:
        'An alphanumeric (a-f, A-F, 0-9) string matching the following format: 00000000-0000-0000-0000-000000000000',
    FORG_tenant_invite_form_help_text:
        'Invite a tenant to this organization using either its name or ID. Subtenants cannot be invited.',
    FORG_tenant_invite_form_use_name_radio_label: 'Invite using a tenant name',
    FORG_tenant_invite_form_use_id_radio_label: 'Invite using a tenant ID',
    FORG_user_invite_form_help_text:
        'Invite a user to this organization using either their email or ID.',
    FORG_user_invite_form_email_help_text: 'A valid email address; for example, example@boomi.com',
    FORG_invite_modal_mode_label: 'Invite using ${mode}',
    FORG_user_invite_form_use_id_radio_label: 'Invite using a user ID',
    FORG_user_invite_form_user_email_input_label: 'Email',
    FORG_user_invite_form_user_id_input_label: 'ID',
    FORG_invite_form_send_button_label: 'Send Invite',
    FORG_invite_form_cancel_button_label: 'Cancel',
    FORG_provision_tenant_name_input_label: 'Name',
    FORG_provision_tenant_user_select_label: 'Email',
    FORG_provision_tenant_cancel_button_label: 'Cancel',
    FORG_provision_tenant_submit_button_label: 'Create',
    FORG_provision_tenant_user_select_help_text:
        'Select an email, or type a new one and click "Add".',
    FORG_provision_tenant_user_select_validation_message: 'Please select an email',
    FORG_non_admin_joined_tenant_heading: 'Organizations',
    FORG_non_admin_joined_tenant_help_text: 'This tenant is in the organization:',
    FORG_non_admin_not_joined_tenant_heading: 'Tenant Invitations',
    'edit page with none selected': 'Please select or create a page before editing',
    FORG_leave_organization_button_label: 'Leave this organization',

    FORG_invite_list_remove_invitation_button_label: 'Remove invitation',
    FORG_user_invite_list_caption: 'Users with pending or rejected invitations',
    FORG_tenant_invite_list_caption: 'Tenants with pending or rejected invitations',
    FORG_received_invite_list_accept_invite: 'Accept',
    FORG_received_invite_list_reject_invite: 'Reject',

    FORG_joined_tenants_table_caption: 'Tenants in the organization',
    FORG_joined_tenants_remove_button_label: 'Remove tenant',
    FORG_joined_users_table_caption: 'Current administrators',
    FORG_joined_users_table_button_label: 'Remove admin user',
    FORG_unknown_field_value_text: 'Unknown',

    FLOW_delete_confirmation_title: 'Delete Flow',
    FLOW_unable_to_delete: 'Unable to delete flow: ${flowName}',
    FLOW_delete_confirmation_message: 'Are you sure you want to delete the "${flowName}" flow?',

    AUTH_welcome: 'Welcome! Sign in to Flow',
    AUTH_email_address: 'Email Address',
    AUTH_no_account: `Don't have an account? `,
    AUTH_free_trial: 'Sign up for a free trial.',
    AUTH_platform_status: 'Platform status',
    AUTH_reset_sent:
        'If the email address you entered: {0} is associated with your user account, we sent you an email with a link to reset your password. The link expires in 1 hour.',
    AUTH_login_failed:
        'Your sign in information is incorrect. Enter the email address and password associated with your user account.',
    AUTH_forgot_password: 'Reset your password.',
    AUTH_2fa_input_label: 'Authentication Code',
    AUTH_2fa_button: 'Continue',
    AUTH_2fa_error:
        'Your authentication code is incorrect. Enter the code from your authenticator application.',
    AUTH_sso_logged_out: 'You have been logged out successfully.',

    GENERAL_cannot_be_undone: 'This cannot be undone.',

    SIDE_MENU_TITLE: 'Menu',

    LOCAL_RUNTIME_title: 'Local Runtimes',
    LOCAL_RUNTIME_summary: 'Local runtimes allow you to run Flows on your own infrastructure.',
    LOCAL_RUNTIME_create_screen_h2: 'Setup Your Local Runtime Environment',
    LOCAL_RUNTIME_create_screen_h2_summary:
        'Before you create a local runtime connection make sure to setup a Flow runtime on your own infrastructure.',
    LOCAL_RUNTIME_create_screen_h3: 'Configure Your Connection',
    LOCAL_RUNTIME_create_screen_h3_summary:
        'Give your connection a name and select any tenants that you would like to share this connection with.',
    LOCAL_RUNTIME_create_screen_connection: 'Connection',
    LOCAL_RUNTIME_connection_name_input_label: 'Connection name',
    LOCAL_RUNTIME_create_screen_shared_tenants: 'Shared Tenants',
    LOCAL_RUNTIME_create_screen_shared_tenants_summary:
        'You may share this local runtime connection with your other Flow tenants and allow those other tenants to deploy Flows directly to this local environment.',
    LOCAL_RUNTIME_create_screen_submit: 'Create New Local Runtime Connection',
    LOCAL_RUNTIME_create_screen_cancel: 'Cancel',

    LOCAL_RUNTIME_edit_screen_h2: 'Edit Your Connection',
    LOCAL_RUNTIME_edit_screen_submit: 'Save and Close',
    LOCAL_RUNTIME_create_screen_number_tenants_selected_summary:
        'Select Tenants below to share the runtime with.',
    LOCAL_RUNTIME_create_screen_number_tenants_selected: '${tenantsSelected} Selected',
    LOCAL_RUNTIME_token_display_warning:
        'Use the server key generated below to connect your local Flow runtime to the Flow cloud. The token will expire 24 hours after generation. Make sure to copy the token before closing this page. If you lose this token you will have to delete the local runtime connection and create a new one.',
    LOCAL_RUNTIME_token_display_submit: 'Done',
    LOCAL_RUNTIME_token_display_h2: 'This is Your Server Key',
    LOCAL_RUNTIME_delete_modal_title: 'Delete Local Runtime Connection',
    LOCAL_RUNTIME_delete_modal_message:
        'Are you sure you want to delete the ${connectionName} connection?',
    LOCAL_RUNTIME_publish_to_local_runtime_version_warning:
        'Current runtime version is ${version}, we highly recommend to always use the latest version, learn more in our Documentation.',
    LOCAL_RUNTIME_unknown_status_title_text:
        "There has been no ping received from this node in it's lifetime.",
    LOCAL_RUNTIME_online_status_title_text: 'Recent ping suggests your Runtime Node is online.',
    LOCAL_RUNTIME_possible_offline_node_title_text:
        'No ping from your Runtime Node in over a minute, please ensure it is online.',
    LOCAL_RUNTIME_possible_offline_node_warning:
        'You have selected a runtime that has not connected to the platform recently, publishing may fail if the runtime is not online.',
    LOCAL_RUNTIME_create_new_runtime_button_label: 'New Local Runtime Connection',
    LOCAL_RUNTIME_list_edit_runtime_button_label: 'Edit Local Runtime',
    LOCAL_RUNTIME_list_delete_runtime_button_label: 'Delete Local Runtime',
    LOCAL_RUNTIME_list_unknown_version: 'unknown',
    LOCAL_RUNTIME_list_no_last_ping: 'Never',

    TENANT_tenant_name_heading_text: 'Tenant Name',
    TENANT_tenant_users_heading_text: 'Users',
    TENANT_subtenants_heading_text: 'Subtenants',
    TENANT_sso_heading_text: 'Single Sign-on',
    TENANT_security_heading_text: 'Security',
    TENANT_reporting_heading_text: 'Reporting',
    TENANT_tenant_comments_heading_text: 'Comments',
    TENANT_user_description:
        'Users can be added to tenants and subtenants as required, allowing other people in your tenant to sign in to a tenant and access the flows and content within.',
    TENANT_subtenant_description_1: 'You can use subtenants to organize your flows.',
    TENANT_subtenant_description_2:
        'For example, use separate subtenants for business units like HR and Finance.',
    TENANT_developer_summary_label: 'Comments about this Tenant',
    TENANT_security_description: 'Set access restrictions by IP address and IP ranges.',
    TENANT_security_add_ip_range_button_label: ' Add an IP Range',
    TENANT_sso_description:
        'Restrict access to this tenant to users authenticating with single sign-on.',
    TENANT_reporting_description:
        'Flow has a reporting infrastructure, that lets you analyze, store, and query state data from your own database or analytics user interface.',
    TENANT_reporting_endpoint_url_input_label: 'Reporting endpoint URL',
    TENANT_reporting_enable_connector_logging_toggle_label: 'Enable Connector Logging',

    TENANT_add_users_button_label: 'Add Users',
    TENANT_remove_confirm_button_label: 'Remove',
    TENANT_remove_confirmation_title: 'Remove User',
    TENANT_remove_confirmation_message:
        'Are you sure you want to remove the user ${firstName} ${lastName}, ${email}?',
    TENANT_update_role_title: 'Change user role?',
    TENANT_update_role_message:
        'Are you sure you want to update the user ${email}, from ${currentRole} to ${newRole}? Changing a role will change the access and privileges assigned to a user.',
    TENANT_update_role_success_message: "The user's role has been successfully updated",
    TENANT_assign_new_role_confirm_button_label: 'Assign new role',
    TENANT_role_access_info_link_text: 'See role access information',
    TENANT_users_table_sso_header_text: 'Single Sign-On',
    TENANT_users_table_is_sso_text: 'Yes',
    TENANT_add_users_modal_title: 'Add Users',
    TENANT_add_users_modal_close_button_label: 'Close',
    TENANT_add_users_modal_body_text:
        "Select a role and enter a user's details to add them to this tenant. The added user will receive an email with further instructions.",
    TENANT_add_users_modal_role_select_label: 'Role',
    TENANT_add_users_modal_first_name_input_label: 'First Name',
    TENANT_add_users_modal_last_name_input_label: 'Last Name',
    TENANT_add_users_modal_email_input_label: 'Email',
    TENANT_add_users_modal_comments_input_label: 'Comments',
    TENANT_add_users_modal_add_user_button_label: 'Add User',
    TENANT_add_users_modal_recently_added_users_list_label: 'Recently added users',
    TENANT_add_users_modal_recently_added_users_list_no_users_message: 'No recently added users',
    TENANT_add_users_modal_add_user_success_message: 'Added user to tenant',
    TENANT_add_users_modal_field_required_message: 'Please fill this field',

    TENANT_observability_description:
        'Send runtime trace data for flows to an OpenTelemetry Protocol (OTLP) endpoint using the OpenTelemetry standard, allowing you to troubleshoot, debug, and manage flow runtime performance. Data can be sent directly to an application performance monitoring system (APM) that supports direct OTLP ingestion, or to an OpenTelemetry Collector managed by your Organization.',
    TENANT_name_input_label: 'Tenant name',
    TENANT_naming_restrictions_text:
        'Tenant names must be between three and one hundred characters long and can contain only alphanumeric characters (a-z, A-Z, 0-9), hyphens (-), and periods (.)',
    TENANT_subtenant_rename_warning:
        'Renaming this tenant will also rename all its subtenants accordingly.',
    TENANT_create_subtenant_name_input_label: 'The name for the subtenant',
    TENANT_create_subtenant_create_button_label: 'Create',
    TENANT_create_subtenant_cancel_button_label: 'Back',
    TENANT_name_validity_message_invalid_characters:
        'Tenant names cannot include these characters: ${characters}',
    TENANT_name_validity_message_wrong_length:
        'Tenant names must be between three and one hundred characters long',
    TENANT_save_settings_button_label: 'Save Settings',

    TENANT_observability_description_1:
        'Send runtime trace data for flows to an OpenTelemetry Protocol (OTLP) endpoint using the OpenTelemetry standard, allowing you to troubleshoot, debug, and manage flow runtime performance.',
    TENANT_observability_description_2:
        'Data can be sent directly to an application performance monitoring system (APM) that supports direct OTLP ingestion, or to an OpenTelemetry Collector managed by your Organization.',
    TENANT_page_title_tenant: 'Tenant',
    TENANT_page_title_new_user: 'New Flow User',
    TENANT_page_title_create_subtenant: 'Create Subtenant',
    TENANT_import_confirm_message_1:
        'Importing a Tenant will overwrite all information in the current Tenant and reload the screen.',
    TENANT_import_confirm_message_2:
        'It is highly recommended to backup your Tenant information using the export button before importing.',
    TENANT_import_confirm_message_3: 'You are about to import ${fileName} into',
    TENANT_import_confirm_label: 'Please type the Tenant name to confirm you wish to proceed.',
    TENANT_import_description: 'Import Tenant data into this Tenant.',
    TENANT_import_drop_zone_message: 'Drop exported tenant file here, or click to browse to import',
    TENANT_import_abort_message: 'Tenant import cancelled.',
    TENANT_import_success_message: 'Your tenant package has been successfully imported.',
    TENANT_import_confirm_button_label: 'Import and overwrite',
    TENANT_import_cancel_button_label: 'Cancel',
    TENANT_import_modal_title: 'Importing Tenant',

    TENANT_switcher_no_tenants_message_1:
        'You are not a user in any tenants. If you believe this is incorrect, please contact your administrator. Alternatively, contact the Flow support team at the address below, quoting your user ID.',
    TENANT_switcher_no_tenants_message_2: 'Your user ID: ',
    TENANT_switcher_no_tenants_message_3: 'Flow support team email address: ',

    TENANT_theme_heading: 'Themes',
    TENANT_theme_description:
        'Enable themes to apply themes to running flows to customize their appearance.',
    TENANT_toggle_message: 'Enable Themes',

    ID_validity_message_invalid_characters: 'IDs cannot include these characters:',
    ID_validity_message_wrong_format:
        'IDs must match the following format string: 00000000-0000-0000-0000-000000000000',
    ID_validity_message_wrong_length: 'IDs must be exactly thirty-six characters long',

    USER_register_new_user_help_text:
        'Please enter the details below to provision a new Flow user account.',
    USER_register_new_user_first_name_input_label: 'First Name',
    USER_register_new_user_last_name_input_label: 'Last Name',
    USER_register_new_user_email_input_label: 'Email',
    USER_register_new_user_register_button_label: 'Register User',
    USER_register_new_user_back_button_label: 'Back',
    USER_email_validity_message_wrong_format:
        'Input must conform to the email format; for example, example@boomi.com',

    OTLP_endpoint_label: 'OTLP Endpoint',
    OTLP_endpoint_help_1: 'OpenTelemetry Protocol (OTLP) endpoint to send data to.',
    OTLP_endpoint_help_2:
        'Only RPC over HTTPS (Remote Procedure Call over HTTPS) endpoints are supported.',

    OTLP_headers_label: 'OTLP Headers',
    OTLP_headers_help_1:
        'Optional list of headers to send with the data, such as credentials for authentication to the APM.',
    OTLP_headers_help_2:
        'Multiple headers must be comma-separated, for example “name1=value1,name2=value2”.',

    OTLP_sensitive_data_label: 'Enable OTLP sensitive data logging',
    OTLP_sensitive_data_help_1:
        'Choose whether to include potentially sensitive information in your OTLP logs, such as detailed connector request and response information.',
    OTLP_sensitive_data_help_2: 'Do not enable this feature in a production environment.',

    OTLP_sensitive_none: 'None - do not log potentially sensitive data',
    OTLP_sensitive_on_error: 'On Error - log only error information',
    OTLP_sensitive_all: 'On Error and Success - log both error and success information',

    MACRO_delete_confirmation_title: 'Delete Macro',
    MACRO_delete_confirmation_message: 'Are you sure you want to delete the "${macroName}" macro?',
    MACRO_save_success_message: 'Successfully saved macro',
    MACRO_create_success_message: 'Successfully created macro',
    MACRO_delete_success_message: 'Successfully deleted macro',
    MACRO_unsaved_changes_message: 'This macro contains unsaved changes',
    MACRO_macro_select_label: 'Macros',
    MACRO_macro_select_default_option_label: 'Select a macro to edit',
    MACRO_new_macro_modal_title: 'New Macro',
    MACRO_new_macro_modal_cancel_button_label: 'Cancel',
    MACRO_new_macro_modal_save_button_label: 'Save',
    MACRO_new_macro_modal_name_input_label: 'Name',
    MACRO_delete_macro_modal_delete_button_label: 'Delete',
    MACRO_invalid_macro_name_message:
        'Macro name cannot be empty or consist entirely of whitespace',
    MACRO_no_selected_macro_message: 'No macro selected',

    PLAYER_invalid_player_name_message:
        'A player with this name already exists or this name contains invalid characters. Valid characters include letters, numbers, and hyphens',
    PLAYER_invalid_player_base_message: 'A base player is required',
    PLAYER_player_select_default_option_label: 'Select a player to edit',
    PLAYER_flow_select_default_option_label: 'Select a flow to preview',
    PLAYER_player_select_label: 'Players',
    PLAYER_flow_select_label: 'Flows',
    PLAYER_preview_open_button_label: 'Open Preview',
    PLAYER_preview_close_button_label: 'Close Preview',
    PLAYER_preview_iframe_title: 'Player Preview',
    PLAYER_unsaved_changes_messages: 'This player contains un-saved changes',
    PLAYER_delete_player_modal_delete_button_label: 'Delete',
    PLAYER_no_player_or_flow_selected_message: 'No player or flow selected',
    PLAYER_no_player_selected_message: 'No player selected',
    PLAYER_no_flow_selected_message: 'No flow selected',
    PLAYER_save_successful_message: 'Successfully saved player',
    PLAYER_create_successful_message: 'Successfully created player',
    PLAYER_delete_successful_message: 'Successfully deleted player',
    PLAYER_new_player_modal_title: 'New Player',
    PLAYER_new_player_modal_name_input_label: 'Name',
    PLAYER_new_player_modal_base_select_label: 'Base on',
    PLAYER_new_player_modal_base_select_placeholder: 'Select a base',
    PLAYER_new_player_modal_cancel_button_label: 'Cancel',
    PLAYER_new_player_modal_save_button_label: 'Save',
    PLAYER_delete_confirmation_title: 'Delete Player',
    PLAYER_delete_confirmation_message:
        'Are you sure you want to delete the "${playerName}" player?',
    PLAYER_no_tenant_id_message: 'Failed to get ID of logged in tenant',
    PLAYER_preview_modal_title: 'Previewing flow "${flowName}" with player "${playerName}"',

    EDITOR_actions_label: 'Actions',
    EDITOR_new_button_label: 'New',
    EDITOR_save_button_label: 'Save',
    EDITOR_refresh_button_label: 'Refresh',
    EDITOR_delete_button_label: 'Delete',

    copy_platform_key_title: 'Copy Platform Key to clipboard',
    copy_saml_login_url_title: 'Copy Flow login URL to clipboard',
    copy_server_key_title: 'Copy Server Key to clipboard',

    USER_SETTINGS_api_key_description:
        'You can authenticate with the Boomi Flow API using an API Key. Once you have generated an API Key, add a x-boomi-flow-api-key header to your API requests instead of an Authorization header, to authenticate as yourself. This header should contain only the apiKey value of the Flow API Key.',
    USER_SETTINGS_api_key_validation_message:
        'A key name and a selected Tenant must be provided to generate a key',
    USER_SETTINGS_api_key_remove_confirm_title: 'Are you sure you want to delete this key?',
    USER_SETTINGS_key_remove_confirm_message:
        'Any future requests that use this key will be not be authenticated.',
    USER_SETTINGS_api_generate_success_message: 'API Key successfully generated',
    USER_SETTINGS_api_delete_success_message: 'API Key successfully deleted',
    USER_SETTINGS_no_api_key_selected_message: 'No API key selected',
    USER_SETTINGS_show_api_key_button_label: 'Show',
    USER_SETTINGS_delete_api_key_button_label: 'Delete key',
    USER_SETTINGS_heading: 'User Settings',
    USER_SETTINGS_api_keys_heading: 'API keys',
    USER_SETTINGS_generate_api_key_heading: 'Generate a new API key',
    USER_SETTINGS_api_key_name_input_label: 'Key Name',
    USER_SETTINGS_tenant_select_label: 'Tenant',
    USER_SETTINGS_tenant_select_default_value_label: 'Please select a tenant',
    USER_SETTINGS_generate_api_key_button_label: 'Generate Key',
    USER_SETTINGS_confirm_delete_api_key_button_label: 'Delete',
    USER_SETTINGS_unknown_error_message: 'Unknown error',

    GRAPH_config_panel_save: 'Save',
    GRAPH_config_panel_cancel: 'Cancel',
    GRAPH_config_panel_revert: 'Revert',
    GRAPH_action_edit: 'Edit',
    GRAPH_action_edit_metadata: 'Edit Metadata',
    GRAPH_action_copy: 'Copy',
    GRAPH_action_paste: 'Paste',
    GRAPH_action_delete: 'Delete',
    GRAPH_action_reset_outcome: 'Reset Position',
    GRAPH_config_panel_add: 'Add',
    GRAPH_config_panel_create_outcome: 'Create Outcome',
    GRAPH_action_view_insights: 'View Insights',
    GRAPH_action_view_traffic_ratio: 'View Traffic Ratio',

    GRAPH_action_no_shortcut: '---',
    GRAPH_action_copy_shortcut: 'CTRL-C',
    GRAPH_action_paste_shortcut: 'CTRL-V',
    GRAPH_action_delete_shortcut: 'DELETE',
    GRAPH_action_edit_shortcut: 'SPACE',
    GRAPH_action_edit_metadata_shortcut: 'SHIFT-ENTER',
    GRAPH_action_view_insights_shortcut: 'CTRL-I',
    GRAPH_action_view_traffic_ratio_shortcut: 'CTRL-SHIFT-I',

    GRAPH_start_outcome_name: 'Go',
    GRAPH_start_single_outcome_error: 'Start map element can only have one outcome',

    GRAPH_metadata_editor_title: 'Edit Metadata',
    GRAPH_metadata_editor_save_success_message: 'Map Element saved successfully',
    GRAPH_metadata_editor_saving_metadata_alert_text:
        'Saving changes to the metadata will overwrite any un-saved changes in the editor',
    GRAPH_metadata_editor_unsupported_element_type_error_message:
        'Cannot save or load metadata of unsupported element type "${type}"',
    create_group_parents_error:
        'Cannot create a group because some selected elements are in different groups or swimlanes',

    SWIMLANE_CONFIG_auth_type_label: 'What type of user authentication should be used?',
    SWIMLANE_CONFIG_auth_type_validation_message: 'You must select an authentication type',
    SWIMLANE_CONFIG_comment_label: 'Comments about this Swimlane',
    SWIMLANE_CONFIG_services_label: 'Which connector will users authenticate with?',
    SWIMLANE_CONFIG_services_validation_message: 'You must select a connector',
    SWIMLANE_CONFIG_services_validation_message_idp: 'You must select an identity provider',
    SWIMLANE_CONFIG_cancel_button_text: 'Cancel',
    SWIMLANE_CONFIG_save_button_text: 'Save Swimlane',

    TYPE_delete_confirmation_title: 'Delete Type',
    TYPE_delete_confirmation_message: 'Are you sure you want to delete the "${typeName}" type?',
    TYPE_delete_property_confirmation_title: 'Delete Property',
    TYPE_delete_property_confirmation_message:
        'Are you sure you want to delete the "${propertyName}" property?',

    VALUE_delete_confirmation_title: 'Delete Value',
    VALUE_delete_confirmation_message: 'Are you sure you want to delete the "${valueName}" value?',
    VALUE_type_selector_placeholder_text: 'Search type by name',
    VALUE_auto_mapping_confidence_title:
        'Matching properties between source type and this type (%)',
    VALUE_auto_mapping_confidence_label: 'Match',

    DATA_ACTION_heading: 'Data Actions',
    DATA_ACTION_add_button_text: 'New Data Action',
    DATA_ACTION_add_criteria_button_text: 'New Criteria',
    DATA_ACTION_edit_filter_button_text: 'Edit Filter',
    DATA_ACTION_filter_type_label: 'Which type of filter should be used?',
    DATA_ACTION_comparator_label: 'The criteria should be compared using',
    DATA_ACTION_order_direction_label: 'How should the data be sorted?',
    DATA_ACTION_number_of_records_label: 'Number of records to return (0 for all)',
    DATA_ACTION_order_by_type_property: 'Which column should the data be ordered by?',
    DATA_ACTION_criteria_type_property: 'Which property/column do you want to filter by?',
    DATA_ACTION_criteria_filter: 'The criteria to filter by',
    DATA_ACTION_load_value_label: 'Which Value do you want to load data into?',
    DATA_ACTION_value_to_delete_label: 'Which Value do you want to delete the data for?',
    DATA_ACTION_value_to_save_label: 'Which Value do you want to save?',

    DATA_ACTION_load_binding_label: 'Where will the data be sourced from?',
    DATA_ACTION_delete_binding_label: 'Where will the data be deleted from?',
    DATA_ACTION_save_binding_label: 'Where will the data be saved?',

    DATA_ACTION_criteria_value_applied_label: 'Which Value should be applied in the filter?',
    DATA_ACTION_filter_value_identifier_label: 'Load the data with the following identifier',
    DATA_ACTION_property_to_filter_by_label: 'Which property/column do you want to filter by?',
    DATA_ACTION_smart_save_toggle_label:
        'Enable smart save of data (connector must support this feature)',
    DATA_ACTION_save_filter_button_text: 'Apply Filter',
    DATA_ACTION_save_criteria_button_text: 'Apply Criteria',
    DATA_ACTION_value_with_no_connector_error:
        'You must select a value that is bound to a Connector.',

    IDENTITY_PROVIDER_delete_confirmation_title: 'Delete Identity Provider',
    IDENTITY_PROVIDER_delete_confirmation_message:
        'Are you sure you want to delete the "${name}" identity provider?',
    IDENTITY_PROVIDER_attributes_email: 'Email',
    IDENTITY_PROVIDER_attributes_firstname: 'First Name',
    IDENTITY_PROVIDER_attributes_lastname: 'Last Name',
    IDENTITY_PROVIDER_attributes_role: 'Role',
    IDENTITY_PROVIDER_attributes_groups: 'Groups',
    IDENTITY_PROVIDER_attributes_primarygroupid: 'Primary Group ID',
    IDENTITY_PROVIDER_attributes_primarygroupname: 'Primary Group Name',
    IDENTITY_PROVIDER_saml_label: 'SAML',
    IDENTITY_PROVIDER_oidc_label: 'OIDC',
    IDENTITY_PROVIDER_oauth2_label: 'OAUTH2',

    // Field validation error messages to be used in map element configs
    MAP_ELEMENT_name_field_label: 'Name',
    MAP_ELEMENT_name_field_validation_message: 'Please enter a name.',
    MAP_ELEMENT_order_field_validation_message: 'Please enter an order.',
    MAP_ELEMENT_outcomelabel_field_validation_message: 'Please enter a label.',
    MAP_ELEMENT_value_field_validation_message: 'Please select a value.',
    MAP_ELEMENT_property_field_validation_message: 'Please select a property.',
    MAP_ELEMENT_macro_field_validation_message: 'Please select a macro to run.',
    MAP_ELEMENT_command_field_validation_message: 'Please select an option.',
    MAP_ELEMENT_datasource_field_validation_message: 'Please select a data source.',
    MAP_ELEMENT_criteria_field_validation_message: 'Please select a criteria.',
    MAP_ELEMENT_listenertype_field_validation_message: 'Please enter a type.',
    MAP_ELEMENT_connector_field_validation_message: 'Please select a connector.',
    MAP_ELEMENT_navigation_field_validation_message: 'Please select a navigation.',
    MAP_ELEMENT_navigationitem_field_validation_message: 'Please select a navigation item.',
    MAP_ELEMENT_attribute_key_required_validation_message: 'Attribute key is required.',
    MAP_ELEMENT_attribute_key_select_placeholder: 'Select or create a key',
    MAP_ELEMENT_attribute_key_create_label_template: 'Create ${input} key',

    PROCESS_list_heading: 'Processes',
    PROCESS_list_add: 'Add Process',
    PROCESS_list_column_name: 'Name',
    PROCESS_list_column_accountId: 'Account Id',
    PROCESS_list_column_order: 'Order',
    PROCESS_label: 'Process',
    PROCESS_open_in_integrate: 'Open process in Integrate',
    PROCESS_option_open: 'Open',
    PROCESS_wait_for_process_to_finish_label: 'Wait for process to finish',
    PROCESS_order_label: 'The order in which this Process should be executed',
    PROCESS_disable_label: 'Disable this Process',
    PROCESS_dynamic_process_property_heading: 'Dynamic Process Properties',
    PROCESS_dynamic_process_property_add: 'Add Dynamic Process Property',
    PROCESS_dynamic_process_property_column_key: 'Key',
    PROCESS_dynamic_process_property_column_value: 'Value',
    PROCESS_process_properties_heading: 'Process Properties',
    PROCESS_process_properties_label: 'Process Properties',
    PROCESS_process_properties_placeholder: 'Select a Process Properties',
    PROCESS_process_properties_required: 'Process Properties is required',
    PROCESS_process_properties_names_error: 'Could not load Process Properties Names: ${error}',
    PROCESS_process_properties_error: 'Could not load Process Properties: ${error}',
    PROCESS_process_property_add: 'Add Process Properties',
    PROCESS_process_property_name_loading: 'Loading...',
    PROCESS_process_property_name_not_found: 'Cannot find Process Properties with Id ${id}',
    PROCESS_process_property_column_name: 'Name',
    PROCESS_process_property_column_label: 'Label',
    PROCESS_process_property_column_help_text: 'Help Text',
    PROCESS_process_property_column_type: 'Data Type',
    PROCESS_process_property_column_persisted: 'Persisted',
    PROCESS_process_properties_data_type_string: 'String',
    PROCESS_process_properties_data_type_number: 'Number',
    PROCESS_process_properties_data_type_date: 'Date',
    PROCESS_process_properties_data_type_boolean: 'Boolean',
    PROCESS_process_properties_data_type_password: 'Hidden',
    PROCESS_output_label: 'Output',
    PROCESS_is_required: 'Process is required',

    DISPLAY_NAME_flow: 'Flow',
    DISPLAY_NAME_group_element: 'Group Element',
    DISPLAY_NAME_macro_element: 'Macro',
    DISPLAY_NAME_map_element: 'Map Element',
    DISPLAY_NAME_navigation_element: 'Navigation',
    DISPLAY_NAME_navigation_item: 'Navigation Item',
    DISPLAY_NAME_page: 'Page',
    DISPLAY_NAME_page_layout: 'Page Layout',
    DISPLAY_NAME_connector: 'Connector',
    DISPLAY_NAME_tag: 'Tag',
    DISPLAY_NAME_type: 'Type',
    DISPLAY_NAME_type_element: 'Type',
    DISPLAY_NAME_type_element_binding: 'Binding',
    DISPLAY_NAME_type_element_property: 'Property',
    DISPLAY_NAME_value_element: 'Value',

    REGION_SWITCHER_button_text: 'Regions',

    OUTCOME_use_outcome_for_faults: 'Use this Outcome for Faults',
    OUTCOME_CREATE_heading: 'Create New Outcome',
    OUTCOME_CREATE_message:
        'This will create an outcome from ${source} to the element selected below.',
    OUTCOME_CREATE_element_select_label: 'Where should this outcome go to?',
    OUTCOME_CREATE_element_select_placeholder: 'Select Element',
    OUTCOME_CREATE_element_select_validation: 'Map element is required',
    OUTCOME_CREATE_name_label: 'Outcome name',
    OUTCOME_CREATE_name_validation: 'Outcome name is required',
    OUTCOME_CREATE_label_label: 'Outcome label',
    OUTCOME_CREATE_label_validation: 'Outcome label is required',
    OUTCOME_confirm_move: 'Confirm Move',
    OUTCOME_confirm_move_message:
        'Are you sure you want to move this outcome to ${mapElementName}?',
    OUTCOME_list_heading: 'Outcomes',
    OUTCOME_add_outcome_button_label: 'Add Outcome',
    OUTCOME_add_attribute_button_label: 'Add Attribute',
    OUTCOME_next_map_element_select_label: 'This Outcome leads to',
    OUTCOME_page_action_binding_select_label: 'When this Outcome is selected',
    OUTCOME_order_input_label: 'The order in which this Outcome should be evaluated',
    OUTCOME_order_input_validation_message: 'Please enter an order.',
    OUTCOME_page_object_binding_select_label: 'Place this Outcome with a Component on the Page',
    OUTCOME_is_bulk_action_toggle_label: 'This Outcome should appear at the top of the Component',
    OUTCOME_page_action_type_select_label: 'What kind of action is this?',
    OUTCOME_label_input_label: 'Label',
    OUTCOME_label_input_validation_message: 'Please enter a label.',
    OUTCOME_attribute_value_placeholder_label: 'Choose...',
    OUTCOME_display_attribute_value_icon_label: 'Icon',
    OUTCOME_display_attribute_value_icon_and_text_label: 'Icon and text',
    OUTCOME_display_attribute_value_icon_no_background_label: 'Icon (No background)',
    OUTCOME_type_attribute_value_default_label: 'Default',
    OUTCOME_type_attribute_value_primary_label: 'Primary',
    OUTCOME_type_attribute_value_success_label: 'Success',
    OUTCOME_type_attribute_value_info_label: 'Info',
    OUTCOME_type_attribute_value_warning_label: 'Warning',
    OUTCOME_type_attribute_value_danger_label: 'Danger',
    OUTCOME_target_attribute_value_blank_label: '_blank',
    OUTCOME_target_attribute_value_self_label: '_self',
    OUTCOME_target_attribute_value_parent_label: '_parent',
    OUTCOME_target_attribute_value_top_label: '_top',
    OUTCOME_target_attribute_value_framename_label: 'framename',
    OUTCOME_size_attribute_value_default_label: 'Default',
    OUTCOME_size_attribute_value_large_label: 'Large',
    OUTCOME_size_attribute_value_small_label: 'Small',
    OUTCOME_size_attribute_value_extra_small_label: 'Extra Small',
    OUTCOME_duplicate_attribute_key_error_message_template:
        'Duplicate attribute key: ${key} not allowed.',
    OUTCOME_next_map_element_name_template: 'Go to ${nextMapName}',
    OUTCOME_next_map_element_name_default: 'Go',
    OUTCOME_config_title_template: 'Outcome for: ${mapElementName}',
    OUTCOME_action_binding_type_save_label: 'Save Changed Values',
    OUTCOME_action_binding_type_no_save_label: 'Do Not Save Changed Values',
    OUTCOME_action_binding_type_partial_save_label: 'Partially Save Changed Values',
    OUTCOME_attribute_button_type_label: 'Button type',
    OUTCOME_attribute_button_icon_label: 'Button icon',
    OUTCOME_attribute_button_size_label: 'Button size',
    OUTCOME_attribute_button_uri_label: 'Button uri',
    OUTCOME_attribute_button_target_label: "Button uri 'target' setting",
    OUTCOME_attribute_button_classes_label: 'Button CSS class',
    OUTCOME_attribute_button_display_label: 'Icon display mode',
    OUTCOME_attribute_button_type_property_id_label: 'Type property id to use for uri',

    CANVAS_SETTINGS_header: 'Canvas Settings',
    CANVAS_SETTINGS_element_style_label: 'Element style',
    CANVAS_SETTINGS_border_thickness_label: 'Element border thickness',

    PAGE_COND_BASIC_value_of: 'When the value of ',
    PAGE_COND_BASIC_create_success: 'Page condition successfully created!',
    PAGE_COND_BASIC_error_unsaved_page:
        'You must make sure you have saved this page before creating basic page conditions',
    PAGE_COND_BASIC_error_validation: 'Please make sure you have selected all required fields',
    PAGE_COND_BASIC_save: 'Save Basic Condition',
    PAGE_COND_BASIC_error_no_components:
        'You must create some page components with either boolean or scalar values.',
    PAGE_COND_BASIC_title: 'Create a Basic Condition',
    PAGE_COND_BASIC_instructions_1:
        'Create a condition to change the state of a component in the page layout based on the value of another component',
    PAGE_COND_BASIC_instructions_2: 'To view, edit and delete page conditions use the',
    PAGE_COND_BASIC_instructions_3: 'Advanced Page Conditions Editor',
    PAGE_CONFIG_save_hidden_inputs_label: 'Save Hidden Inputs',
    PAGE_CONFIG_save_hidden_inputs_help_text:
        'Enable hidden components to save content value to state',
    PAGE_delete_confirmation_title: 'Delete Page',
    PAGE_delete_confirmation_message: 'Are you sure you want to delete the "${pageName}" page?',
    PAGE_unable_to_delete: 'Unable to delete page: ${pageName}',
    PAGE_unable_to_load_list: 'Could not load Pages.',
    PAGE_save_success: 'Page saved successfully',
    PAGE_clone_success: 'Page cloned successfully',
    PAGE_element_copy_success: 'Page component/container copied successfully.',
    PAGE_element_paste_error: 'A page component/container has not been copied yet.',
    PAGE_BUILDER_loading_page: 'Loading page...',
    PAGE_BUILDER_name_field_validation_message: 'Please enter a name.',
    PAGE_BUILDER_field_is_required_validation_message: 'This field is required.',
    PAGE_BUILDER_data_source_modal_title: 'Changing Data Source',
    PAGE_BUILDER_data_source_filter_modal_title: 'Changing Data Source Filter',
    PAGE_BUILDING_data_source_2_title: 'Data source',
    PAGE_BUILDING_data_source_2_connector_label: 'Which connector do you want to get data from?',
    PAGE_BUILDING_data_source_2_type_label: 'The Type of the data',
    PAGE_BUILDER_state_value_modal_title: 'Changing State Value',
    PAGE_BUILDER_state_value_modal_message:
        'Changing or removing the State Value will reset any existing validation configuration that is no longer applicable.',
    PAGE_BUILDER_delete_component_modal_title: 'Delete component',
    PAGE_BUILDER_unable_to_save_page_modal_title: 'Unable to save page',
    PAGE_BUILDER_unable_to_load_page: 'Unable to load selected page',
    PAGE_BUILDER_unable_to_clear_page: 'Unable to clear current page',
    PAGE_BUILDER_unable_to_save_page: 'Unable to save current page',
    PAGE_BUILDER_unable_to_delete_page: 'Unable to delete current page',
    PAGE_BUILDER_unable_to_load_type_and_bindings: 'Unable to load type and bindings',
    PAGE_BUILDER_unable_to_load_columns: 'Unable to load columns',
    PAGE_BUILDER_unable_to_load_services: 'Unable to load connectors',
    PAGE_BUILDER_new_page_modal_title: 'New page',
    PAGE_BUILDER_delete_page_modal_title: 'Delete page',
    PAGE_BUILDER_load_page_modal_title: 'Load page',
    PAGE_BUILDER_configure_component_section_title: 'Component',
    PAGE_BUILDER_configure_component_section_placeholder:
        'Add a new component or select an existing component to configure it.',
    PAGE_BUILDER_page_name_not_valid: 'Entered page name is not valid. Page name can not be empty.',
    PAGE_BUILDER_no_space_to_insert_component:
        'Unable to insert the selected component. There is not enough space in the grid at current location. Please pick another cell or add more columns.',
    PAGE_BUILDER_no_columns: 'You must configure data presentation columns',
    PAGE_BUILDER_delete_page_condition_title: 'Delete page condition',
    PAGE_BUILDER_unsaved_changes_title: 'Unsaved changes',
    PAGE_BUILDER_delete_page_condition_message:
        'Are you sure you want to delete this page condition?',
    PAGE_BUILDER_delete_page_condition_rule_title: 'Delete page condition rule',
    PAGE_BUILDER_delete_page_condition_rule_message:
        'Are you sure you want to delete this page condition rule?',
    PAGE_BUILDER_delete_page_condition_operation_title: 'Delete page condition operation',
    PAGE_BUILDER_delete_page_condition_operation_message:
        'Are you sure you want to delete this page condition operation?',
    PAGE_BUILDER_page_condition_unsaved_changes_title: 'Modified page condition',
    PAGE_BUILDER_page_condition_unsaved_changes_message:
        'Current page condition was modified but the changes have not been applied. Are you sure you want to proceed?',
    PAGE_BUILDER_page_condition_not_configured_message:
        'Current condition is not fully configured. You need to add at least one rule and one operation.',
    PAGE_BUILDER_page_conditions_heading: 'Configure page conditions',
    PAGE_BUILDER_unsaved_changes_banner_message: 'This page contains unsaved changes.',
    PAGE_BUILDER_summary_error: 'Unable to generate the summary',
    PAGE_BUILDER_criteria_error: 'Unable to load criteria options',
    PAGE_BUILDER_page_conditions_invalid_structure:
        'Page conditions on this page have an invalid data structure.',
    PAGE_BUILDER_page_conditions_invalid_content_types:
        'Select components or values that use compatible content types.',
    PAGE_BUILDER_page_conditions_rule_invalid_content_types_message:
        '${contentTypeOne} type cannot be compared to ${contentTypeTwo} type using the ${criteria} criteria.',
    PAGE_BUILDER_page_conditions_operation_invalid_content_types_message:
        '${contentTypeOne} type cannot be set to ${contentTypeTwo} type.',
    PAGE_BUILDER_page_conditions_invalid_criteria:
        'Select criteria that can be used with the selected content type.',
    PAGE_BUILDER_page_conditions_invalid_criteria_message:
        '${contentType} property or value cannot use the ${criteriaType} criteria.',
    PAGE_BUILDER_validation_attribute_label: 'Regex for custom validation',
    PAGE_BUILDER_mask_attribute_label: 'Mask the input field',
    PAGE_BUILDER_autocomplete_attribute_label: 'Perform auto-completion',
    PAGE_BUILDER_type_attribute_label: 'Define a non-standard input type',
    PAGE_BUILDER_step_attribute_label: 'Stepping interval',
    PAGE_BUILDER_minimum_attribute_label: 'Minimum value',
    PAGE_BUILDER_maximum_attribute_label: 'Maximum value',
    PAGE_BUILDER_useCurrent_attribute_label: 'Default to current date/time',
    PAGE_BUILDER_dateTimeFormat_attribute_label: 'Define a custom date/time format',
    PAGE_BUILDER_dateTimeLocale_attribute_label: 'Define a custom date/time locale',
    PAGE_BUILDER_multiSelect_attribute_label: 'Allow multiple selections',
    PAGE_BUILDER_shape_attribute_label: 'A custom CSS class',
    PAGE_BUILDER_background_attribute_label: 'Custom background colour',
    PAGE_BUILDER_isTethered_attribute_label: 'Absolutely position the dropdown',
    PAGE_BUILDER_isRowSelectionDisabled_attribute_label: 'Disable row selection',
    PAGE_BUILDER_radio_attribute_label: 'Display column headers as radio fields',
    PAGE_BUILDER_borderless_attribute_label: 'Display without borders',
    PAGE_BUILDER_striped_attribute_label: 'Display with striped rows',
    PAGE_BUILDER_classes_attribute_label: 'Space delimited css classes',
    PAGE_BUILDER_outcome_classes_attribute_label: 'Space delimited css classes per outcome',
    PAGE_BUILDER_label_attribute_label: 'Label to display in the legend',
    PAGE_BUILDER_ordered_attribute_label: 'Display as an ordered list',
    PAGE_BUILDER_justify_attribute_label: 'Css class to justify the button layout',
    PAGE_BUILDER_group_attribute_label: 'Horizontal or vertical grouping',
    PAGE_BUILDER_columns_attribute_label: 'Column layout size',
    PAGE_BUILDER_size_attribute_label: 'Button size',
    PAGE_BUILDER_file_resource_path_label: 'Resource Path',
    PAGE_BUILDER_file_connector_id_label: 'The connector to load the files from',
    PAGE_BUILDER_file_path_label: 'The OpenApi path to upload files to',
    PAGE_BUILDER_loading_paths: 'Loading OpenApi paths',
    PAGE_BUILDER_select_path: 'Select a path',
    PAGE_BUILDER_crossorigin_attribute_label: 'Button size',
    PAGE_BUILDER_ismap_attribute_label: 'Specifies an image as a server-side image map',
    PAGE_BUILDER_loading_attribute_label:
        'Specifies whether a browser should load an image immediately or to defer loading of images until some conditions are met',
    PAGE_BUILDER_longdesc_attribute_label: 'Specifies a URL to a detailed description of an image',
    PAGE_BUILDER_referrerpolicy_attribute_label:
        'Specifies which referrer information to use when fetching an image',
    PAGE_BUILDER_sizes_attribute_label: 'Specifies image sizes for different page layouts',
    PAGE_BUILDER_srcset_attribute_label:
        'Specifies a list of image files to use in different situations',
    PAGE_BUILDER_assistant_attribute_label: 'Assistant message label',
    PAGE_BUILDER_user_attribute_label: 'User message label',
    PAGE_BUILDER_usemap_attribute_label: 'Specifies an image as a client-side image map',
    PAGE_BUILDER_enableexport_attribute_label: 'Enable exporting data to a csv file',
    PAGE_BUILDER_exportfilename_attribute_label: 'File name for csv export',
    PAGE_BUILDER_chart_columns_error: 'All dataset columns should be the same.',
    PAGE_BUILDER_chart_type_error: 'All dataset chart types should be the same.',
    PAGE_BUILDER_chart_data_source_type_error: 'All dataset data source types should be the same.',
    PAGE_BUILDER_unconfigured_container: 'Page container is not fully configured',
    PAGE_BUILDER_unconfigured_component: 'Page component is not fully configured',
    PAGE_BUILDER_delete_dataset_title: 'Delete selected dataset',
    PAGE_BUILDER_delete_dataset_message: 'Are you sure you want to delete the selected dataset?',
    PAGE_BUILDER_preview_component_placeholder_label: 'Placeholder label',
    PAGE_BUILDER_payment_card_name_on_card_input_label: 'Name on card',
    PAGE_BUILDER_payment_card_card_number_input_label: 'Card number',
    PAGE_BUILDER_payment_card_expiry_date_input_label: 'Expiry date',
    PAGE_BUILDER_payment_card_security_code_input_label: 'Security code',
    PAGE_BUILDER_payment_card_postal_code_input_label: 'ZIP or post code',
    PAGE_BUILDER_validation_add: 'Add Validation',

    GROUP_comment_label: 'Comments about this Group',
    ENVIRONMENT_name: 'Environments',
    ENVIRONMENT_description:
        'Enable environments to manage your flows through our release management process.',
    ENVIRONMENT_not_on: 'Environments is not switched on for this tenant',
    ENVIRONMENT_toggle_message: 'Enable Environments',
    ENVIRONMENT_toggle_disabled:
        'Environments is not supported when the tenant has an MCR instance',
    ENVIRONMENT_default_option: 'Select environment as default',
    ENVIRONMENT_header:
        'Environments allow you to manage and deploy your flow releases through the different phases of a standard development life cycle. Releases are first automatically deployed to the Development environment. You can then promote a release into the Test environment for testing, and then into the Production environment to make the release publicly available.',
    ENVIRONMENT_loading: 'Loading Environments',
    ENVIRONMENT_add_new: 'Add New Environment',
    ENVIRONMENT_no_releases: 'No releases.',
    ENVIRONMENT_edit: 'Edit Environments',
    ENVIRONMENT_delete: 'Delete Environment',
    ENVIRONMENT_delete_message:
        'Are you sure you want to delete the "${variableName}" environment?',
    ENVIRONMENT_save: 'Save',
    ENVIRONMENT_cancel: 'Cancel',
    ENVIRONMENT_build_subtext:
        'Where would you like to add this Flow? You can add this build to an existing release or create a new release. All releases are built and automatically deployed to the development environment.',
    ENVIRONMENT_build_subtext_theme:
        'Where would you like to add this Theme? You can add this build to an existing release or create a new release. All releases are built and automatically deployed to the development environment.',
    ENVIRONMENT_build_create_release: 'Create a new release',
    ENVIRONMENT_build_add_to_release: 'Add to existing release',
    ENVIRONMENT_create_release: 'To create a new release, enter the details below.',
    ENVIRONMENT_build_create_release_button: 'Build and create new release',
    ENVIRONMENT_add_to_release:
        'Please select a release to build this Flow in. Flows can only be added to releases within Test environments.',
    ENVIRONMENT_build_flow: 'Build Flow',
    ENVIRONMENT_promote: 'Promote to ',
    ENVIRONMENT_promote_release: 'Promote release',
    ENVIRONMENT_promote_release_message:
        '${releaseName} was successfully promoted to ${environmentName}',
    ENVIRONMENT_promote_release_success: 'Promote release successful',
    ENVIRONMENT_promote_subtext:
        'Promote the ${releaseName} release to the next environment? Releases can only be promoted onto the next environment. Ensure that the target environment is correct before deploying this release. Releases can be rolled back after promotion.',
    ENVIRONMENT_promote_target_environment: 'Target environment:',
    ENVIRONMENT_finish: 'Finish',
    ENVIRONMENT_rollback: 'Rollback to previous release',
    ENVIRONMENT_rollback_release: 'Rollback Release',
    ENVIRONMENT_delete_release: 'Delete Release',
    ENVIRONMENT_delete_release_confirmation_message:
        'Please confirm that you want to delete the ${releaseName} release',
    ENVIRONMENT_releases_title: 'Releases',
    ENVIRONMENT_rollback_release_confirmation: 'Please confirm that you want to rollback ',
    ENVIRONMENT_save_button_title: 'Save the environment settings',
    ENVIRONMENT_releases_tab_title: 'Manage releases that have been deployed to the environment',
    ENVIRONMENT_flows_tab_title: 'View Flows that have been deployed to this environment',
    ENVIRONMENT_variable_tab_title: 'Manage the environments environment variables',
    ENVIRONMENT_settings_tab_title: 'Manage the environments settings',
    ENVIRONMENT_integration_accounts_tab_title: 'Integration Accounts',
    ENVIRONMENT_remove_item_confirmation_title: 'Remove from release',
    ENVIRONMENT_remove_item_confirmation_message:
        'Are you sure you want to remove ${itemName} from the ${releaseName} release?',
    ENVIRONMENT_remove_item_success: 'Successfully removed from release',
    ENVIRONMENT_default: 'Default Environment',
    ENVIRONMENT_not_default: 'Not Default Environment',
    ENVIRONMENT_run_in: 'Run in ',
    ENVIRONMENT_preview_in: 'Preview in ',
    ENVIRONMENT_run: 'Run',
    ENVIRONMENT_filters_select_environment: 'Select an Environment',
    ENVIRONMENT_new_environment_variable: 'Create a new environment variable',
    ENVIRONMENT_edit_environment_variable: 'Edit environment variable',
    ENVIRONMENT_delete_environment_variable_title: 'Delete environment variable',
    ENVIRONMENT_delete_environment_variable_message:
        'Are you sure you want to delete the "${variableName}" environment variable?',
    ENVIRONMENT_setting_turned_off: 'Environments is not switched on for this tenant',
    ENVIRONMENT_must_be_admin: 'You must be an administrator to view the environment pages',
    ENVIRONMENT_integration_account_environment: 'Integration Account Environment',
    ENVIRONMENT_select_integration_account_environment: 'Select an Integration Account Environment',
    ENVIRONMENT_select_theme: 'Select a Theme',
    ENVIRONMENT_select_flow: 'Select a Flow',
    ENVIRONMENT_releases_table_run_preview_header: 'Run/Preview',
    ENVIRONMENT_save_success_message: 'Environment successfully saved',
    ENVIRONMENT_delete_success_message: 'Environment successfully deleted',
    ENVIRONMENT_missing_context_provider_message:
        'useEnvironmentsProviders must be used within a EnvironmentsProvider',

    BUTTON_reorder_move_up_title: 'Move up',
    BUTTON_reorder_move_down_title: 'Move down',

    IMPORT_EXPORT_export_help_text:
        'Export all Tenant data and published flows within this tenant to a file.',
    IMPORT_EXPORT_export_button_label: 'Export',
    IMPORT_EXPORT_sharing_token_label:
        'Copy the sharing token and paste into the import section to import the flow into another tenant.',
    IMPORT_EXPORT_sharing_token_placeholder: 'Your sharing token will be displayed here',
    IMPORT_EXPORT_conflicting_dependencies_table_caption: 'Conflicting dependencies',
    IMPORT_EXPORT_conflicting_dependencies_table_id_column_heading: 'ID',
    IMPORT_EXPORT_conflicting_dependencies_table_name_column_heading: 'Name',
    IMPORT_EXPORT_conflicting_dependencies_table_type_column_heading: 'Type',
    IMPORT_EXPORT_conflicting_dependencies_table_flow_name: 'Flow',
    IMPORT_EXPORT_conflicting_dependencies_table_group_element_name: 'Group Element',
    IMPORT_EXPORT_conflicting_dependencies_table_macro_element_name: 'Macro Element',
    IMPORT_EXPORT_conflicting_dependencies_table_map_element_name: 'Map Element',
    IMPORT_EXPORT_conflicting_dependencies_table_navigation_element_name: 'Navigation Element',
    IMPORT_EXPORT_conflicting_dependencies_table_page_element_name: 'Page Element',
    IMPORT_EXPORT_conflicting_dependencies_table_service_element_name: 'Connector Element',
    IMPORT_EXPORT_conflicting_dependencies_table_tag_element_name: 'Tag Element',
    IMPORT_EXPORT_conflicting_dependencies_table_type_element_name: 'Type Element',
    IMPORT_EXPORT_conflicting_dependencies_table_value_element_name: 'Value Element',
    IMPORT_EXPORT_conflicting_dependencies_table_custom_page_component_element_name:
        'Component Element',
    IMPORT_EXPORT_conflicting_dependencies_table_identity_provider_element_name:
        'Identity Provider Element',
    IMPORT_EXPORT_conflicting_dependencies_table_unknown_element_name: 'Unknown Element',
    IMPORT_EXPORT_generate_sharing_token: 'Generate Sharing Token',
    IMPORT_EXPORT_download_package: 'Download Package',
    IMPORT_EXPORT_default_flow_option_label: 'Select a flow',
    IMPORT_EXPORT_import_success_message: 'Flow ${flowName} imported successfully',
    IMPORT_EXPORT_loading_message: 'Processing...',
    IMPORT_EXPORT_export_section_heading: 'Export',
    IMPORT_EXPORT_environment_select_placeholder: 'Select an environment',
    IMPORT_EXPORT_flow_select_placeholder: 'Select a flow',
    IMPORT_EXPORT_include_passwords_checkbox_description:
        'Select this checkbox to include passwords in the export package. By default, passwords are not included in an exported flow. Any connector passwords will need to be set once a flow has been imported as your connectors may not work as expected until they have been set.',
    IMPORT_EXPORT_include_passwords_checkbox_label: 'Include passwords in package export',
    IMPORT_EXPORT_import_section_heading: 'Import',
    IMPORT_EXPORT_import_shared_package_section_heading: 'Import Shared Package',
    IMPORT_EXPORT_sharing_token_input_description:
        "If you've been given an import token, paste it here and the shared package will be imported into your tenant.",
    IMPORT_EXPORT_shared_token_input_placeholder: 'Enter a shared package token',
    IMPORT_EXPORT_import_button_label: 'Import',
    IMPORT_EXPORT_file_drop_placeholder:
        'Drop exported package file here, or click to browse to import',
    IMPORT_EXPORT_download_link_text: 'Download',
    IMPORT_EXPORT_sharing_token_section_heading: 'Sharing token',

    IMPORT_confirm_title: 'Confirm Import',
    IMPORT_confirm_message: 'Are you sure you want to import a flow into your tenant: ${tenant}?',
    IMPORT_confirm_message_with_flow_name:
        'Are you sure you want to import the flow "${flowName}" into your tenant: ${tenant}?',
    IMPORT_confirm_overwrite_title: 'Overwrite existing elements?',
    IMPORT_confirm_overwrite_body_1:
        'We have detected that this Flow will import some shared elements that already exist in this Tenant.',
    IMPORT_confirm_overwrite_body_2:
        "This can happen if you've already installed this Flow and are now reinstalling without having deleted all values, types, connectors etc. that the Flow had previously imported.",
    IMPORT_confirm_overwrite_body_3:
        "If you continue, these elements will be overwritten and any changes you've made to these elements will be undone.",
    IMPORT_confirm_overwrite_body_4: 'Are you sure you wish to continue?',

    TABS_close_page_layout: 'Close Page Layout',
    TABS_page_unsaved_warning_1:
        'This page layout contains unsaved changes. If you close this page layout they will be lost.',
    TABS_page_unsaved_warning_2: 'Are you sure you want to close the page layout?',
    RUNTIME_legacy_runtime_prefix: 'Legacy',
    RUNTIME_early_access_runtime_prefix: 'Default',
    RUNTIME_title_text: 'This component only works with the ${runtime} runtime',

    ARRANGE_no_elements_error: 'Cannot arrange 0 elements',
    ARRANGE_illegal_selections_error:
        'Cannot arrange selected elements in unselected groups. Please select the parent group of all deeper elements.',

    TENANT_integration_accounts: 'Integration Accounts',
    TENANT_integration_accounts_add: 'Add Integration Account',
    TENANT_integration_accounts_invalid: 'Integeration account details are not valid',
    TENANT_integration_accounts_username: 'Email Address',
    TENANT_integration_accounts_account_id: 'Account Id',
    TENANT_integration_accounts_api_key: 'Atomsphere API Token',
    TENANT_integration_accounts_username_required: 'Email address is required',
    TENANT_integration_accounts_api_key_required:
        'Atomsphere API Token is required and must be a valid Atomsphere API token',
    TENANT_integration_accounts_account_id_required: 'Account Id is required',
    TENANT_integration_accounts_added_success: 'Integration account added successfully',

    ASSET_edit_modal_title: 'Edit Asset',
    ASSET_upload_not_accepted: 'File size/type not accepted.',
    ASSET_delete_confirmation_title: 'Delete Asset',
    ASSET_delete_confirmation_message: 'Are you sure you want to delete ${assetName}?',
    ASSET_manager_modal_title: 'Asset Manager',
    ASSET_manager_modal_close_button_label: 'Close',
    ASSET_manager_modal_insert_asset_button_label: 'Insert Asset',
    ASSET_manager_modal_no_asset_selected_message: 'Please select an asset',
    ASSET_manager_modal_asset_deleted_message: 'Selected asset has been deleted',

    TYPES_import_profile: 'Import Profile',
    TYPES_import_profile_description:
        'Import a JSON profile from Integrate and create matching types',
    TYPES_import_profile_no_integration_accounts:
        'No Integration Accounts have been configured in this tenant. You can add an Integration Account on the Tenant page, accessed via the Settings menu',
    TYPES_import_profile_account: 'Account',
    TYPES_import_profile_account_placeholder: 'Select an Account',
    TYPES_import_profile_profile: 'Profile',
    TYPES_import_profile_profile_placeholder: 'Select a Profile',
    TYPES_import_profile_types: 'Types',
    TYPES_import_profile_properties_invalid: 'One or more properties must be selected',
    TYPES_import_profile_import: 'Import Types',
    TYPES_import_profile_types_loading: 'Loading Types...',
    TYPES_import_profile_no_types_label: 'No Types',
    TYPES_import_profile_no_types_text: 'Could not generate types for this profile',
    TYPES_import_profile_types_saving: 'Saving Types...',
    TYPES_delete_system_type_error_message: 'Deleting system types is not allowed.',
    TYPES_types_list_title: 'Types',
    TYPES_new_type_button_label: 'New Type',
    TYPES_refresh_results_button_label: 'Refresh Results',
    TYPES_save_type_button_label: 'Save Type',
    TYPES_comments_input_placeholder: 'Comments about this Type',
    TYPES_name_input_placeholder: 'A name for this type',
    TYPES_name_validation_message: 'Name field is required',
    TYPES_heading_text: 'Type: ',
    TYPES_id_label: 'ID: ',
    TYPES_connector_name_label: 'Connector Name: ',
    TYPES_connector_id_label: 'Connector ID: ',
    TYPES_new_property_button_label: 'New Property',
    TYPES_properties_required_error_message: 'You must add properties',
    TYPES_properties_heading: 'Properties',
    TYPES_property_detail_heading: 'Property for: ',
    TYPES_name_field_validation_message: 'Name field is required',
    TYPES_content_type_field_validation_message: 'Content type field is required',
    TYPES_property_type_field_validation_message: 'Property type field is required',
    TYPES_property_type_input_label: 'What kind of Property is this?',
    TYPES_property_format_input_label: 'The format that should be applied to this Property',
    TYPES_property_format_input_placeholder: 'Enter an output format specification',
    TYPES_apply_property_button_label: 'Apply Property',

    TYPE_PROPERTY_PICKER_select_all: 'Select All',
    TYPE_PROPERTY_PICKER_deselect_all: 'De-select All',
    TYPE_PROPERTY_PICKER_search_placeholder: 'Search for properties...',
    TYPE_PROPERTY_PICKER_properties_removed: 'Object / List properties have been removed',

    // Translations
    TRANSLATIONS_main_header: 'Translation',
    TRANSLATIONS_export_header: 'Export Flow For Translation',
    TRANSLATIONS_export_button: 'Export to XLIFF',
    TRANSLATIONS_import_header: 'Import Translations',
    TRANSLATIONS_source_select_placeholder: 'Select a source language...',
    TRANSLATIONS_target_select_placeholder: 'Select a target language...',
    TRANSLATIONS_confirm_import_message:
        'Select how you would like to handle elements that are missing translations.',
    TRANSLATIONS_import_success: 'Import Successful.',
    TRANSLATIONS_import_dropzone_placeholder:
        'Drop completed Flow translation template here, or click to browse.',
    TRANSLATIONS_type_error: 'Do not import',
    TRANSLATIONS_type_error_help_text:
        'Prevent the translation from importing and provide an error message with the ID of the element missing translation.',
    TRANSLATIONS_type_default_language: 'Import using the source language',
    TRANSLATIONS_type_default_language_help_text:
        'Replace each missing translation with the source language for the element.',
    TRANSLATIONS_type_custom_text: 'Import with custom text',
    TRANSLATIONS_type_custom_text_help_text:
        'Replace all missing translations with the text you enter below. Cannot be empty.',

    COMPONENT_SUGGEST_list_has_no_valid_properties:
        'This List has only ${invalidContentTypesString} properties which are not valid to display in this component.',

    AUDIT_title: 'Auditing',
    AUDIT_event_environment_created:
        '${datetime} ${user} created a new environment called: ${environmentName}',
    AUDIT_event_environment_deleted:
        '${datetime} ${user} deleted the ${environmentName} environment',
    AUDIT_event_environment_variable_added:
        '${datetime} ${user} added "${variableName}" environment variable in the ${environmentName} environment',
    AUDIT_event_environment_variable_updated:
        '${datetime} ${user} updated "${variableName}" environment variable in the ${environmentName} environment',
    AUDIT_event_environment_variable_removed:
        '${datetime} ${user} deleted "${variableName}" environment variable in the ${environmentName} environment',
    AUDIT_event_flow_activated: '${datetime} ${user} published "${flowName}" Flow',
    AUDIT_event_flow_deactivated: '${datetime} ${user} deactivated "${flowName}" Flow',
    AUDIT_event_flow_loaded: '${datetime} ${user} opened "${flowName}" Flow',
    AUDIT_event_flow_package_exported:
        '${datetime} ${user} exported the flow package for "${flowName}"',
    AUDIT_event_flow_package_imported:
        '${datetime} ${user} imported a flow package for "${flowName}"',
    AUDIT_event_flow_package_shared:
        '${datetime} ${user} created a sharing token for the flow "${flowName}"',
    AUDIT_event_flow_saved: '${datetime} ${user} saved "${flowName}" Flow',
    AUDIT_event_flow_added_to_release:
        '${datetime} ${user} added Flow "${flowName}" to the release "${releaseName}"',
    AUDIT_event_flow_removed_from_release:
        '${datetime} ${user} removed Flow "${flowName}" from the release "${releaseName}"',
    AUDIT_event_release_created: '${datetime} ${user} created a release called "${releaseName}"',
    AUDIT_event_release_deleted: '${datetime} ${user} deleted the release "${releaseName}"',
    AUDIT_event_release_deployed:
        '${datetime} ${user} deployed release "${releaseName}" to the ${environmentName} environment',
    AUDIT_event_release_rolled_back:
        '${datetime} ${user} rolled back the release "${releaseName}" in the ${environmentName} environment',
    AUDIT_event_tenant_clearing_applied: '${datetime} ${user} applied the tenant content clearance',
    AUDIT_event_tenant_clearing_request: '${datetime} ${user} requested to clear tenant content',
    AUDIT_event_tenant_created: '${datetime} ${user} created the tenant',
    AUDIT_event_tenant_deletion_applied: '${datetime} ${user} applied the tenant deletion request',
    AUDIT_event_tenant_deletion_requested: '${datetime} ${user} requested to delete the tenant',
    AUDIT_event_tenant_exported: '${datetime} ${user} exported the tenant',
    AUDIT_event_tenant_imported: '${datetime} ${user} imported a tenant',
    AUDIT_event_tenant_import_started: '${datetime} ${user} started to import a tenant',
    AUDIT_event_tenant_key_added: '${datetime} ${user} created an API key',
    AUDIT_event_tenant_key_removed: '${datetime} ${user} removed an API key',
    AUDIT_event_tenant_saved: '${datetime} ${user} saved the tenant',
    AUDIT_event_theme_deleted: '${datetime} ${user} deleted theme "${themeName}"',
    AUDIT_event_theme_added_to_release:
        '${datetime} ${user} added theme "${themeName}" to release "${releaseName}"',
    AUDIT_event_theme_removed_from_release:
        '${datetime} ${user} removed theme "${themeName}" from release "${releaseName}"',
    AUDIT_event_theme_saved: '${datetime} ${user} saved theme "${themeName}"',
    AUDIT_event_user_added:
        '${datetime} ${user} added a new user ${newUserFirstName} ${newUserLastName} (${newUserEmail})',
    AUDIT_event_user_logged_in: '${datetime} ${user} logged into Flow',
    AUDIT_event_user_updated_role:
        '${datetime} ${user} updated the role for user ${newUserFirstName} ${newUserLastName} (${newUserEmail}) from ${oldRoleName} to ${newRoleName}',
    AUDIT_event_user_removed:
        '${datetime} ${user} removed user ${newUserFirstName} ${newUserLastName} (${newUserEmail})',
    AUDIT_event_default: '${datetime} ${user} triggered the ${eventType} event',
    AUDIT_FILTER_all_event_types: 'All event types',
    AUDIT_FILTER_all_users: 'All active users',
    AUDIT_FILTER_download_csv: 'Download results in CSV format',
    AUDIT_FILTER_refresh_results: 'Refresh results',
    AUDIT_time_period_filter_select_label: 'Time period',
    AUDIT_event_type_filter_select_label: 'Event type',
    AUDIT_user_filter_select_label: 'User',
    AUDIT_date_range_input_label: 'Date range',

    COMPONENT_SUGGEST_list_has_no_number_properties:
        'This List has no Number properties to display in this component.',
    COMPONENT_SUGGEST_loading_suggestions: 'Loading component suggestions...',
    COMPONENT_SUGGEST_no_suggestions: 'No suggestions available',
    COMPONENT_SUGGEST_suggest_options_view_title: 'We can show this value like:',
    COMPONENT_SUGGEST_multiple_columns_view_title: 'Which columns do you want to show?',
    COMPONENT_SUGGEST_single_column_view_title: 'Which column do you want to show?',
    COMPONENT_SUGGEST_object_properties_view_title: 'Select a property to show',
    COMPONENT_SUGGEST_object_properties_none_valid:
        'The Object "${elementName}" cannot be shown, and it does not have any properties that can be shown.',
    COMPONENT_SUGGEST_object_properties_some_valid:
        'The Object "${elementName}" cannot be shown, but these properties can be shown:',
    COMPONENT_SUGGEST_object_properties_valid_info_1: 'Valid values or properties must be either:',
    COMPONENT_SUGGEST_object_properties_valid_info_2: 'or an Object of Type "$Payment Card".',
    NOTIFICATION_dismiss_button_label: 'Dismiss notification',
    SSO_EXPIRY_NOTIFICATION_expiration_time_message:
        'Your SSO session is due to expire in ${remainingMinutes} minute(s) at ${expiryTime}.',
    SSO_EXPIRY_NOTIFICATION_refresh_session_link_text: 'Click here to refresh your session.',
    THEME_theme_name_help_message: 'Valid characters include letters, numbers, and hyphens',
    THEME_invalid_theme_name_message: 'Name is empty or invalid',

    VALUE_SELECTOR_select_or_create: 'Select or create a new Value',
    VALUE_SELECTOR_select: 'Select',
    VALUE_SELECTOR_edit: 'Edit',
    VALUE_SELECTOR_remove: 'Remove',
    VALUE_SELECTOR_hide_filters: 'Hide Filters',
    VALUE_SELECTOR_show_filters: 'Show Filters',
    VALUE_SELECTOR_filters_type: 'Type',
    VALUE_SELECTOR_filters_content_type: 'Content Type',
    VALUE_SELECTOR_filters_access: 'Access',
    VALUE_SELECTOR_create_new: 'Create a new Value',
    VALUE_SELECTOR_recent: 'Recent',
    VALUE_SELECTOR_more_results: 'More Results',
    VALUE_SELECTOR_unknown_error: 'Unknown error occurred getting or saving a Value',

    AUTO_MAPPING_type_loading: 'Loading Types...',
    AUTO_MAPPING_type_no_property_match: 'No match for this property',
    AUTO_MAPPING_type_unmatched_properties: 'Unmatched Properties',

    VALUE_default: 'A default applied to this Value',
    VALUE_default_boolean: 'Set default state to TRUE',

    VALUE_EDITOR_default: 'A default applied to this Value',
    VALUE_EDITOR_default_boolean: 'Set default state to TRUE',
    VALUE_EDITOR_modal_title_edit: 'Edit Value',
    VALUE_EDITOR_modal_title_new: 'Create a new Value',
    VALUE_EDITOR_system_value_error: 'Saving system Values is not allowed',
    VALUE_EDITOR_value_name: 'Name',
    VALUE_EDITOR_value_name_placeholder: 'Enter a name for this Value',
    VALUE_EDITOR_value_content_type: 'What kind of Value is this?',
    VALUE_EDITOR_value_access: 'Access',
    VALUE_EDITOR_value_is_versionless: 'The Value should be versioned',
    VALUE_EDITOR_value_is_secret: `This Value is a secret. It's contents will not be visible at designtime or runtime`,
    VALUE_EDITOR_value_is_secret_exists:
        'Default content or Object Data has already been defined for this secret Value',
    VALUE_EDITOR_value_default_content_value: 'A default applied to this Value',
    VALUE_EDITOR_value_default_obectdata: 'Default Object Data',
    VALUE_EDITOR_value_content_format: 'The format that should be applied to this Value',
    VALUE_EDITOR_value_content_format_placeholder: 'Enter an output format specification',
    VALUE_EDITOR_value_developer_summary: 'Comments',
    VALUE_EDITOR_value_developer_summary_placeholder: 'Comments about this Value',

    OBJECT_DATA_EDITOR_add_new_item: 'Add New ${typeName}',
    OBJECT_DATA_EDITOR_remove: 'Remove',
    OBJECT_DATA_EDITOR_filter_properties_placeholder: 'Filter properties',

    URL_TARGET_blank_help_text: 'Opens the link in a new browser tab',
    URL_TARGET_self_help_text: 'Opens the link in the current browser tab',

    VERSION_SECTION_heading: 'Snapshots',
    VERSION_SECTION_description_1: 'Activate a previously built snapshot of the Flow.',
    VERSION_SECTION_description_2:
        'A snapshot is a version of a Flow which is created every time you "Build & Run" or "Publish" a Flow.',
    VERSION_SECTION_description_3:
        'Activating a snapshot will only change the version of the Flow that users see when they run this Flow without a specific version id.',
    VERSION_SECTION_description_4: 'The build version will not be affected.',
    VERSION_SECTION_deactivate_all_snapshots: 'Deactivate All Flow Snapshots',
    VERSION_SECTION_deactivate_flow_environment: 'Deactivate Flow',
    VERSION_SECTION_deactivate_all_snapshots_confirm:
        'Are you sure you want to deactivate all snapshots for this Flow?',
    VERSION_SECTION_deactivate_all_snapshots_confirm_environments:
        'Are you sure you want to deactivate this Flow? This will remove the flow from all environments',
    VERSION_SECTION_deactivate_all_snapshots_success: 'Flow was successfully deactivated',
    VERSION_SECTION_deactivate_all_snapshots_environment_text:
        'Deactivate Flow by taking the Flow out of every environment',

    GROUPS_USERS_GROUPS_SEARCH_PLACEHOLDER: 'Search to add groups',
    GROUPS_USERS_USERS_SEARCH_PLACEHOLDER: 'Search to add users',
    GROUPS_USERS_GROUPS_HEADING: 'Groups',
    GROUPS_USERS_USERS_HEADING: 'Users',
    GROUPS_USERS_GROUPS_FETCH_ERROR_MESSAGE:
        'Encountered an error when contacting the connector for Group restrictions. Please ensure the connector is accessible.',
    GROUPS_USERS_USERS_FETCH_ERROR_MESSAGE:
        'Encountered an error when contacting the connector for User restrictions. Please ensure the connector is accessible.',
    GROUPS_USERS_NONE_SELECTED_ERROR_MESSAGE:
        'You must specify at least one user or group relationship for the permissions, otherwise no one will be able to gain access.',

    THEMES_import_heading: 'Import a Theme',
    THEMES_export_heading: 'Export a Theme',
    THEMES_select: 'Select a Theme',
    THEMES_export: 'Export Theme',
    THEMES_import_placeholder: 'Drop an exported Theme file here, or click to browse.',
    THEMES_confirm_import_message:
        'Are you sure you want to import the theme "${themeName}" into your tenant: ${tenant}? If a theme with this name already exists it will be overwritten.',
    THEMES_confirm_import_message_without_name:
        'Are you sure you want to import the theme into your tenant: ${tenant}? If a theme with this name already exists it will be overwritten.',
    THEMES_import_success: 'Theme imported sucessfully',
    THEMES_header: 'Theme: ${themeName}',
    THEMES_name: 'Name',
    THEMES_document_title: 'Document Title',
    THEMES_title: 'Title',
    THEMES_colours: 'Colors',
    THEMES_general: 'General',
    THEMES_background: 'Background',
    THEMES_background_tooltip: 'The main background color of the application.',
    THEMES_background_alt: 'Background alternate',
    THEMES_background_alt_tooltip:
        'The alternate background color of repeating elements such as table rows.',
    THEMES_font_colour: 'Font',
    THEMES_font_colour_tooltip: 'The text color.',
    THEMES_primary: 'Primary',
    THEMES_primary_tooltip:
        'The accent color. Used for button borders, primary buttons (Save, Submit etc.), table headings.',
    THEMES_primary_font: 'Primary font',
    THEMES_primary_font_tooltip:
        'The color of text within primary buttons (Save, Submit etc.) and table headings.',
    THEMES_border: 'Border',
    THEMES_border_tooltip: 'The color of borders.',
    THEMES_selected: 'Selected',
    THEMES_selected_background: 'Selected background',
    THEMES_selected_background_tooltip:
        'The background color of selected items such as selected table rows, checked checkboxes, toggles etc.',
    THEMES_selected_font_colour: 'Selected font',
    THEMES_selected_font_colour_tooltip:
        'The text color of selected items such as selected table rows, checked checkboxes, toggles etc.',
    THEMES_highlighted: 'Highlighted',
    THEMES_highlighted_background: 'Highlight background',
    THEMES_highlighted_background_tooltip:
        'The background color of highlighted items such as hovered table rows, hovered select options etc.',
    THEMES_highlighted_font: 'Hightlight font',
    THEMES_hightlighted_font_tooltip:
        'The text color of highlighted items such as hovered table rows, hovered select options etc.',
    THEMES_font_header: 'Fonts',
    THEMES_font_subheader: 'Font classification',
    THEMES_font_default: 'Default',
    THEMES_font_system_ui: 'System UI',
    THEMES_font_transitional: 'Transitional',
    THEMES_font_old_style: 'Old Style',
    THEMES_font_humanist: 'Humanist',
    THEMES_font_geometric_humanist: 'Geometric Humanist',
    THEMES_font_classical_humanist: 'Classic Humanist',
    THEMES_font_neo_grotesque: 'Neo-Grotesque',
    THEMES_font_monospace_slab_serif: 'Monospace Slab Serif',
    THEMES_font_monospace_code: 'Monospace Code',
    THEMES_font_industrial: 'Industrial',
    THEMES_font_rounded_sans: 'Rounded Sans',
    THEMES_font_slab_serif: 'Slab Serif',
    THEMES_font_antique: 'Antique',
    THEMES_font_didone: 'Didone',
    THEMES_font_handwritten: 'Handwritten',
    THEMES_font_select_help:
        'These are just examples of the font classification that will be used. The actual font used and what you see here depends on what is available on your browser or operating system',
    THEMES_files: 'Files',
    THEMES_custom_log: 'Custom Logo',
    THEMES_custom_logo_placeholder: 'Logo URL',
    THEMES_custom_css_header: 'Custom CSS Stylesheets',
    THEMES_custom_css_placeholder: 'Stylesheet URL',
    THEMES_custom_css_error_msg: 'URL is required',
    THEMES_add_stylesheet: 'Add CSS Stylesheet',
    THEMES_custom_js_header: 'Custom JavaScript files',
    THEMES_custom_js_placeholder: 'JavaScript file URL',
    THEMES_custom_js_error: 'URL is required',
    THEMES_search: 'Search',
    THEMES_add_js_files: 'Add JavaScript file',
    THEMES_preview_header: 'Preview example',

    ANOMALY_type_error: 'Error Rate',
    ANOMALY_type_usage: 'Flow Usage',
    ANOMALY_type_time: 'Map Element Time',
    ANOMALY_error_modal_title: 'Error',
    ANOMALY_anomaly: 'Anomaly',
    ANOMALY_number_tooltip: 'Number of anomalies',
    ANOMALY_total_tooltip: 'Total Anomalies',
    ANOMALY_bar_chart_title: 'Anomalies (By Anomaly Type)',
    ANOMALY_donut_chart_title: 'Total Anomalies',
    ANOMALY_donut_chart_legend: 'Anomaly Types',
    ANOMALY_table_title: 'Anomalies in the last',
    ANOMALY_day: 'day',
    ANOMALY_3_days: '3 days',
    ANOMALY_7_days: '7 days',
    ANOMALY_table_header_type: 'Anomaly Type',
    ANOMALY_table_header_details: 'Details',
    ANOMALY_table_header_map_element: 'Map Element',
    ANOMALY_date_time: 'Date Time',
    ANOMALY_anomaly_detection_title: 'Anomaly Detection',
    ANOMALY_detail_time_spent: ' seconds spent before moving',
    ANOMALY_detail_usage: ' flow uses per hour',
    // constructed into a sentence e.g. 'more' 'usage ''than average'
    ANOMALY_reason_more: 'more',
    ANOMALY_reason_less: 'less',
    ANOMALY_reason_errors: 'errors ',
    ANOMALY_reason_time: 'time spent on map element ',
    ANOMALY_reason_usage: 'usage ',
    ANOMALY_reason_than_average: 'than average',
    ANOMALY_no_anomalies_found: 'No anomalies found',
    ANOMALY_check_your_filters: 'Check your filters as some results are being hidden',
    ANOMALY_no_anomalies_7_days: 'No anomalies have been detected in the last 7 days',
    ANOMALY_select_type: 'Select an Anomaly Type',
    ANOMALY_select_flow: 'Select a Flow',
    ANOMALY_select_state_id: 'Select a State Id',
    ANOMALY_filter_to_last_day: 'Filter to last day',
    ANOMALY_1_day_short: '1D',
    ANOMALY_filter_to_last_3_days: 'Filter to last 3 days',
    ANOMALY_3_days_short: '3D',
    ANOMALY_filter_to_last_7_days: 'Filter to last 7 days',
    ANOMALY_7_days_short: '7D',
    ANOMALY_no_error_message: 'No error message found',
    ANOMALY_limit_reached_suggestion:
        'For large tenants, we recommend configuring your tenant to send data to your APM system.',
    ANOMALY_limit_reached_learn_more_1: 'Learn more about ',
    ANOMALY_limit_reached_learn_more_2: 'configuring observability in a tenant',
    ANOMALY_limit_reached_learn_more_3: ' in our documentation',

    SHOW_HIDE_hide_button_label: 'hide',
    SHOW_HIDE_show_button_label: 'show',

    DEBUGGER_confirmation_title: 'This map element is in a different flow',
    DEBUGGER_confirmation_summary:
        'Please confirm if you would you like to switch to flow ${flowName}.',
    DEBUGGER_confirmation_summary_tab_conflict: 'Flow ${flowName} is already open in another tab.',

    DASHBOARD_header: 'Insights Dashboard',
    DASHBOARD_inpect: 'Inspect',
    DASHBOARD_go_to_flow_title: 'Go to ${flowName}',
    DASHBOARD_filter_last_year: '1Y',
    DASHBOARD_filter_last_year_tooltip: 'Filter to the last year',
    DASHBOARD_filter_last_month: '30D',
    DASHBOARD_filter_last_month_tooltip: 'Filter to the last 30 days',
    DASHBOARD_filter_last_week: '7D',
    DASHBOARD_filter_last_week_tooltip: 'Filter to the last 7 days',
    DASHBOARD_filter_last_day: '24H',
    DASHBOARD_filter_last_day_tooltip: 'Filter to the last 24 hours',
    DASHBOARD_filter_calendar_tooltip: 'Filters',
    DASHBOARD_recent_anomalies: 'Recent Anomalies',
    DASHBOARD_no_anomalies: 'No anomalies in the last 24 hours. Click here to see all.',
    DASHBOARD_usage_header: 'Usage',
    DASHBOARD_errors_header: 'Errors',
    DASHBOARD_anomalies_header: 'Anomalies',
    DASHBOARD_states_header: "States for '${flowName}'",
    DASHBOARD_errors_filter_flow_playerholder: 'Select a Flow',
    DASHBOARD_search: 'Search',
    DASHBOARD_flow_name: 'Flow name',
    DASHBOARD_no_usage: 'No usage data found',
    DASHBOARD_no_usage_unfilterd: 'Adjust your time range to see more or check back later',
    DASHBOARD_no_errors: 'No errors found',
    DASHBOARD_error_line_header: 'Over time',
    DASHBOARD_error_pie_header: 'Proportional By Flow',
    DASHBOARD_error_pie_header_alt: 'Proportional By Element',
    DASHBOARD_in_progress: 'In Progress',
    DASHBOARD_done: 'Done',
    DASHBOARD_errored: 'Errored',
    DASHBOARD_expired: 'Expired',
    DASHBOARD_all: 'All',
    DASHBOARD_log: 'Log',
    DASHBOARD_downloading_states: 'Downloading state data',
    DASHBOARD_connector_request_header: 'Connector Logs',
    DASHBOARD_connect_log_events: 'Connector Log Events',
    DASHBOARD_root_faults_header: 'Root Faults',
    DASHBOARD_failures: 'Failures',
    DASHBOARD_failed: 'Failed',
    DASHBOARD_ok: 'OK',

    OPENAPI_TABLE_path: 'Path',
    OPENAPI_TABLE_create_type: 'Create Type(s)',
    OPENAPI_TABLE_operation: 'HTTP Method',
    OPENAPI_TABLE_description: 'Description',
    OPENAPI_TABLE_request_object_name: 'Request Object Name',
    OPENAPI_TABLE_response_object_name: 'Response Object Name',
    OPENAPI_TABLE_type_name_placeholder: 'Enter a name for this Type',
    OPENAPI_TABLE_configure_table: 'Configure Table',
    OPENAPI_TABLE_no_types_error:
        'No types have been selected for creation, please select at least one',
    OPENAPI_TABLE_empty_schema_error: 'Cannot save blank OpenApi schema configuration',

    OPENAPI_tables: 'Tables',
    OPENAPI_table_configuration: 'Configure Table',
    OPENAPI_select_property: 'Select List Property',
    OPENAPI_root_property: 'root',
    OPENAPI_list_property: 'List Property',
    OPENAPI_property_help:
        'If the root of the response is not a list of items, then choose which property contains a list of items',
    OPENAPI_id: 'ID',
    OPENAPI_order_by: 'Order By',
    OPENAPI_order_direction: 'Order Direction',
    OPENAPI_limit: 'Limit',
    OPENAPI_offset: 'Offset',
    OPENAPI_search: 'Search',
    OPENAPI_list_filter_properties: 'List Filter Properties',
    OPENAPI_list_filter_help:
        'Use this section to map the default names of the table filter properties to the actual names that the OpenAPI endpoint expects.',
    OPENAPI_list_filter_help_example:
        'For example if the endpoint expects a "query" to be provided instead of a "search" then input "query" into the Search input.',

    FLOW_HISTORY_no_snapshots: 'No Releases',
    FLOW_HISTORY_no_snapshots_description: 'This flow has not been released yet',
    FLOW_HISTORY_no_changes: 'No Changes',
    FLOW_HISTORY_no_changes_description: 'No changes were found between the two releases selected',
    FLOW_HISTORY_restore_confirm_1:
        'Restoring to this version will lose any unbuilt changes to this Flow and its shared elements.',
    FLOW_HISTORY_restore_confirm_2:
        "If you continue, your Flow and its shared element's changes will be restored back to state they had on",
    FLOW_HISTORY_restore_conflict_1:
        'We have detected that restoring this Flow version will revert some shared elements in this Tenant.',
    FLOW_HISTORY_restore_conflict_2: 'These shared elements may be being used by other Flows.',
    FLOW_HISTORY_restore_conflict_3:
        'If you continue, these elements will be restored back to state they had on',
    FLOW_HISTORY_automatic_restore_build_comment:
        'Automatic build of data before restoration of version ${versionName}',
    FLOW_HISTORY_start_comparing_button: 'Compare Releases',
    FLOW_HISTORY_compare_button: 'Compare',
    FLOW_HISTORY_please_select_releases: 'Please select two releases',
    FLOW_HISTORY_loading_releases: 'Loading Releases...',
    FLOW_HISTORY_loading_changes: 'Loading Changes...',

    FEATURES_heading: 'Features',
    FEATURES_description:
        'Below is a list of currently available features for this tenant. Some of these features are still in development and experimental. Enabling a feature could cause unexpected results or corrupt data. The functionality of these features will change without warning.',

    DEPENDENTS_table_title: 'Dependents',
    SIDEBAR_show_sidebar_button_label: 'Show sidebar',
    SIDEBAR_hide_sidebar_button_label: 'Hide sidebar',

    FOOTER_platform_status_and_announcements_label: 'Platform and Status Announcements',
    FOOTER_copyright_label: '© Copyright ${date} Boomi, inc.',
    FOOTER_privacy_label: 'Privacy',

    API_TOOL_url_input_loading_placeholder: 'Loading API endpoint suggestions...',
    API_TOOL_url_input_placeholder: 'Type or select an API endpoint URL',
    API_TOOL_url_input_label: 'Request URL',
    API_TOOL_get_request_button_label: 'GET',
    API_TOOL_post_request_button_label: 'POST',
    API_TOOL_put_request_button_label: 'PUT',
    API_TOOL_delete_request_button_label: 'DELETE',
    API_TOOL_request_body_input_label: 'Request',
    API_TOOL_response_body_input_label: 'Response',
    API_TOOL_request_success_message: '${method} request to ${requestUrl} completed successfully',
} as const;
